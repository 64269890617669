import React from 'react'
import PropTypes from 'prop-types'

import './Icon.scss'
import { icons } from './svgIcons'


export function Icon(props) {

  return (
    <div
      className={`
        icon
        icon-${props.icon}
        variant-${props.variant}
        shape-${props.shape}
        color-${props.color}
        size-${props.size}
        ${props.className || ''}
      `}
    >
      <svg
        role="img"
        aria-hidden={props.ariaHidden}
        aria-label={props.ariaLabel || props.icon}
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
      >
        {icons[props.icon]}
      </svg>
    </div>
  )
}


Icon.propTypes = {
  ariaHidden: PropTypes.bool,
  ariaLabel:  PropTypes.string,
  icon:       PropTypes.oneOf(Object.keys(icons)),
  variant:    PropTypes.oneOf(['flat', 'ghost', 'secondary']),
  shape:      PropTypes.oneOf(['circle', 'rounded', 'square']),
  color:      PropTypes.oneOf(['primary', 'default', 'red', 'orange', 'yellow', 'green', 'blue', 'purple', 'pink', 'grey', 'white']),
  size:       PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl', 'xxl', 'responsive']),
  className:  PropTypes.string,
}

Icon.defaultProps = {
  ariaHidden: true,
  variant:    'secondary',
  shape:      'circle',
  color:      'primary',
  size:       'l',
}