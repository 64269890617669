import { token } from '../Dashboard'
import { getUrlParams } from './getUrlParams'

const cache = {}


export async function getSimpleData(query, abortController = new AbortController()) {

  if(cache[JSON.stringify(query)]) return cache[JSON.stringify(query)]

  const headers = new Headers({
    Authorization: `Basic ${token}`,
    'Content-Type': 'application/json'
  })

  const options = {
    method: 'GET',
    headers: headers,
    redirect: 'follow',
    signal: abortController.signal
  }

  const url = `https://lrs.academieportal.nl/api/statements/aggregate?pipeline=${encodeURIComponent(JSON.stringify(query))}`

  return window.fetch(url, options)
  .then(response => response.json())
  .then(json => {
    cache[JSON.stringify(query)] = json
    return json
  })
  .catch(error => console.log(error))
}



export async function getData(query, abortController = new AbortController()) {

  if(cache[JSON.stringify(query)]) return cache[JSON.stringify(query)]

  const headers = new Headers({
    Authorization: `Basic ${token}`,
    'Content-Type': 'application/json'
  })

  const options = {
    method: 'GET',
    headers: headers,
    redirect: 'follow',
    signal: abortController.signal
  }

  const url = `https://lrs.academieportal.nl/api/statements/aggregateAsync?pipeline=${encodeURIComponent(JSON.stringify(query))}`
  
  const response = await window.fetch(url, options)
  .then(response => response.json())
  .catch(error => console.log(error))

  if(!response) return

  if(!response.result) {
    await sleep(2000)
    return await getData(query, abortController)
  }
  
  cache[JSON.stringify(query)] = response.result

  return response.result
}



function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}



async function sleep(ms) {
  await timeout(ms)
}

export const lrsData = (function() {
  const isHistorical = !['false', '0', false, null].includes(getUrlParams('historical'));

  return {
    string: isHistorical ? 'http://skillstown' : 'http://onlineacademy.nl',
    selector: isHistorical ? 'http://skillstown' : 'http://onlineacademy&46;nl',
    historical: isHistorical
  }
})();