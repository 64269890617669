import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Line } from 'react-chartjs-2'
import { Spinner, Tooltip, Button } from '../base'
import { CSVLink } from 'react-csv'

import { getData } from '../util/api'
import { loginsPerHourQuery } from '../util/loginQueries'
import { matchDepartment, matchItem, matchUser, matchType, matchDate } from '../util/filters'
import { formatDate } from '../util/datetime'


const hours = []
for(let i = 0; i < 24; i++) {
  hours.push({
    hour: i,
    value: 0
  })
}


export function LoginsPerHour(props) {

  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [csvData, setCsvData] = useState([])
  const [loading, setLoading] = useState(true)
  const [noResults, setNoResults] = useState(false)
  const chartOptions = {
    scales: {
      xAxes: [{
        type: 'category',
        labels: hours.map(h => h.hour),
        ticks: {
          autoSkip: true,
          maxRotation: 0
        },
        gridLines: {
          display: false
        }
      }]
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => `${tooltipItem[0].label}:00`,
        label: (tooltipItem, data) => {
          let value = Number(tooltipItem.value)
          return t(`chartjs.unique_logins${value === 1 ? '' : '_plural'}`, { count: value })
        }
      }
    }
  }
  

  useEffect(() => {
    const abortController = new AbortController()

    let query = loginsPerHourQuery()
    query = matchDepartment(query, props.departmentFilter)
    query = matchUser(query, props.userFilter, props.includeUser)
    query = matchItem(query, props.itemFilter, props.includeItem)
    query = matchType(query, props.typeFilter, props.includeType)
    query = matchDate(query, props.dateFilter)

    async function fetchData(query) {
      setLoading(true)
      const data = await getData(query, abortController)
      if(!data) return
      
      const chartData = []
      hours.map(item => chartData.push(item.value))
      data.map(item => chartData[item.hour] = item.value)

      let csvData = []
      for(let i = 0; i < 24; i++) {
        csvData.push({
          hour: i,
          value: 0
        })
      }
      for(let i = 0; i < 24; i++) {
        csvData[i].value = chartData[i]
      }
      setCsvData(csvData)
  
      if(data.length === 0) setNoResults(true)
      else setNoResults(false)
  
      setData({
        datasets: [{
          data: chartData
        }]
      })
      setLoading(false)
    }
    fetchData(query)

    return () => abortController.abort()

  }, [props])


  
  const csvHeaders = [
    { key: 'hour', label: t('table.hour') },
    { key: 'value', label: t('table.total_logins') }
  ]



  return (
    <>
      <Tooltip>{t('tooltips.logins_per_hour')}</Tooltip>
      <h4>{t('titles.logins_per_hour')}</h4>
      <div className="chart">
        {noResults ? <span>{t('no_results')}</span> :
          <Line data={data} options={chartOptions} />
        }
      </div>
      <Button label="CSV Export" icon="download" className="csv" size="s" variant="ghost">
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename={`lrs-logins-per-hour-${formatDate(props.dateFilter.startDate)}-${formatDate(props.dateFilter.endDate)}.csv`}
          className="csv-link"
          separator={';'}
        />
      </Button>
      {loading && <Spinner mask />}
    </>
  )
}