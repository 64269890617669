import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { HorizontalBar } from 'react-chartjs-2'
import { Spinner, Tooltip, Button, getColor } from '../base'
import { CSVLink } from 'react-csv'

import { getData } from '../util/api'
import { queries } from '../util/queries'
import { matchDepartment, matchItem, matchUser, matchType, matchDate } from '../util/filters'
import { formatDate } from '../util/datetime'


export function AverageAttemptsPerExam(props) {

  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [csvData, setCsvData] = useState([])
  const [loading, setLoading] = useState(true)
  const [noResults, setNoResults] = useState(false)
  const chartOptions = {
    scales: {
      yAxes: [{
        gridLines: {
          display: false
        }
      }]
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => {
          let type = data.datasets[0].contentTypes[tooltipItem[0].index]
          return `${tooltipItem[0].label} (${type})`
        },
        label: (tooltipItem, data) => {
          let value = Math.round((Number(tooltipItem.value) + Number.EPSILON) * 100) / 100
          return t(`chartjs.attempts${value === 1 ? '' : '_plural'}`, { count: value })
        }
      }
    }
  }
  

  useEffect(() => {
    const abortController = new AbortController()

    let query = queries.averageAttemptsPerExam
    query = matchDepartment(query, props.departmentFilter)
    query = matchUser(query, props.userFilter, props.includeUser)
    query = matchItem(query, props.itemFilter, props.includeItem)
    query = matchType(query, props.typeFilter, props.includeType)
    query = matchDate(query, props.dateFilter)

    async function fetchData(query) {
      setLoading(true)
      let data = await getData(query, abortController)
      if(!data) return

      data.forEach(element => {
        element.average = Number(element.average).toFixed(2)
      })
      
      setCsvData(data)

      data = data.slice(0, 10) // limit to display top 10 in chart
      
      const chartLabels = data.map(item => item.label)
      const chartData = data.map(item => item.average)
      const contentTypes = data.map(item => item.type)
      const contentColors = []
  
      for(let i = 0; i < contentTypes.length; i++) {
        if(contentTypes[i] === 'e-learning')      contentColors[i] = getColor('blue')
        if(contentTypes[i] === 'course')          contentColors[i] = getColor('blue')

        if(contentTypes[i] === 'we-learning')     contentColors[i] = getColor()
        if(contentTypes[i] === 'welearning')      contentColors[i] = getColor()
        
        if(contentTypes[i] === 'webinar')         contentColors[i] = getColor('red')
        if(contentTypes[i] === 'podcast')         contentColors[i] = getColor('pink')
        if(contentTypes[i] === 'essential')       contentColors[i] = getColor('orange')
        if(contentTypes[i] === 'scan')            contentColors[i] = getColor('green')
        if(contentTypes[i] === 'video-feedback')  contentColors[i] = getColor('purple')
      }
  
      if(chartData.length === 0) setNoResults(true)
      else setNoResults(false)
  
      setData({
        labels: chartLabels,
        datasets: [{
          data: chartData,
          backgroundColor: contentColors,
          contentTypes: contentTypes
        }]
      })
      setLoading(false)
    }
    fetchData(query)

    return () => abortController.abort()

  }, [props])


  
  const csvHeaders = [
    { key: '_id', label: 'ID' },
    { key: 'label', label: t('table.item') },
    { key: 'type', label: t('table.type') },
    { key: 'attempts', label: t('table.attempts') },
    { key: 'users', label: t('table.users') },
    { key: 'average', label: t('table.average_attempts') }
  ]



  return (
    <>
      <Tooltip>{t('tooltips.average_attempts')}</Tooltip>
      <h4>{t('titles.average_attempts')}</h4>
      <div className="chart">
        {noResults ? <span>{t('no_results')}</span> :
          <HorizontalBar data={data} options={chartOptions} />
        }
      </div>
      <Button label="CSV Export" icon="download" className="csv" size="s" variant="ghost">
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename={`lrs-average-attempts-${formatDate(props.dateFilter.startDate)}-${formatDate(props.dateFilter.endDate)}.csv`}
          className="csv-link"
          separator={';'}
        />
      </Button>
      {loading && <Spinner mask />}
    </>
  )
}