import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CountUp from 'react-countup'
import { Spinner, Tooltip, Icon } from '../base'

import { getData } from '../util/api'
import { queries } from '../util/queries'
import { matchDepartment, matchItem, matchUser, matchType, matchDate } from '../util/filters'


export function TotalAverageScore(props) {

  const { t } = useTranslation()
  const [total, setTotal] = useState(0)
  const [prevTotal, setPrevTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  

  useEffect(() => {
    const abortController = new AbortController()

    let query = queries.totalAverageScore
    query = matchDepartment(query, props.departmentFilter)
    query = matchUser(query, props.userFilter, props.includeUser)
    query = matchItem(query, props.itemFilter, props.includeItem)
    query = matchType(query, props.typeFilter, props.includeType)
    query = matchDate(query, props.dateFilter)
  
    async function fetchTotal(query) {
      setLoading(true)
      const data = await getData(query, abortController)
      
      if(data && data.length) {
        let score = data[0].score
        setTotal(score)
      }
      else setTotal(0)
      setLoading(false)
    }
    fetchTotal(query)

    return () => {
      abortController.abort()
      setPrevTotal(total)
    }

  }, [props, total])
  


  return (
    loading ? <Spinner mask /> :
    <>
      <Tooltip>{t('tooltips.total_score')}</Tooltip>
      <div className="total">
        <div>
          <Icon icon="star" variant="flat" shape="rounded" size="l" color="orange" />
          <span className="number">
            <CountUp
              isCounting
              duration={0.5}
              decimals={1}
              end={total}
              start={prevTotal}
            /> / 10
          </span>
        </div>
        <span className="label">
          {t('total_score')}
        </span>
      </div>
    </>
  )
}