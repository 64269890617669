import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CSVLink } from 'react-csv'
import { Spinner, Tooltip, Button } from '../base'

import { ExamsTable } from '../tables/ExamsTable'
import { getData } from '../util/api'
import { queries } from '../util/queries'
import { matchDepartment, matchItem, matchUser, matchType, matchDate } from '../util/filters'
import { formatDate } from '../util/datetime'


export function ExamStatistics(props) {

  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [noResults, setNoResults] = useState(false)
  

  useEffect(() => {
    const abortController = new AbortController()

    let query = queries.examStatistics
    query = matchDepartment(query, props.departmentFilter)
    query = matchUser(query, props.userFilter, props.includeUser)
    query = matchItem(query, props.itemFilter, props.includeItem)
    query = matchType(query, props.typeFilter, props.includeType)
    query = matchDate(query, props.dateFilter)

    async function fetchData(query) {
      setLoading(true)
  
      let data = await getData(query, abortController)
      if(!data) return

      data.forEach(element => {
        element.score = element.score ? Number(element.score).toFixed(1) : 0
      })
      
      if(data.length === 0) setNoResults(true)
      else setNoResults(false)
  
      setData(data)
      setLoading(false)
    }
    fetchData(query)

    return () => abortController.abort()

  }, [props])



  const memoData = useMemo(() => data, [data])


  const tableColumns = useMemo(() => [
    { accessor: '_id', Header: 'ID' },
    { accessor: 'label', Header: t('table.item') },
    { accessor: 'type', Header: t('table.type') },
    { accessor: 'attempts', Header: t('table.attempts') },
    { accessor: 'completed', Header: t('table.completed') },
    { accessor: 'certificates', Header: t('table.certificates') },
    { accessor: 'score', Header: t('table.average_score') }
  ], [t])

  const csvHeaders = [
    { key: '_id', label: 'ID' },
    { key: 'label', label: t('table.item') },
    { key: 'type', label: t('table.type') },
    { key: 'attempts', label: t('table.attempts') },
    { key: 'completed', label: t('table.completed') },
    { key: 'certificates', label: t('table.certificates') },
    { key: 'score', label: t('table.average_score') }
  ]



  return (
    <>
      <Tooltip>
        {t('tooltips.exam_stats')}
        <br />
        {t('tooltips.exam_stats_2')}
      </Tooltip>
      <h4>{t('titles.exam_stats')}</h4>
      {noResults ? <span>{t('no_results')}</span> :
        <div className="data-table">
          <ExamsTable
            data={memoData}
            columns={tableColumns}
            departmentFilter={props.departmentFilter}
            userFilter={props.userFilter}
            dateFilter={props.dateFilter}
            includeUser={props.includeUser}
          />
          <Button label="CSV Export" icon="download" className="csv" size="s" variant="ghost">
            <CSVLink
              data={memoData}
              headers={csvHeaders}
              filename={`lrs-exam-stats-${formatDate(props.dateFilter.startDate)}-${formatDate(props.dateFilter.endDate)}.csv`}
              className="csv-link"
              separator={';'}
            />
          </Button>
        </div>
      }
      {loading && <Spinner mask />}
    </>
  )
}