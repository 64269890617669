import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import CountUp from 'react-countup'
import { Spinner, Tooltip, Icon } from '../base'

import { getData } from '../util/api'
import { queries } from '../util/queries'
import { matchDepartment, matchItem, matchUser, matchType, matchDate } from '../util/filters'
import { locale } from '../Dashboard'


export function TotalActiveUsers(props) {

  const { t } = useTranslation()
  const [total, setTotal] = useState(0)
  const [prevTotal, setPrevTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  

  useEffect(() => {
    const abortController = new AbortController()

    let query = queries.totalActiveUsers
    query = matchDepartment(query, props.departmentFilter)
    query = matchUser(query, props.userFilter, props.includeUser)
    query = matchItem(query, props.itemFilter, props.includeItem)
    query = matchType(query, props.typeFilter, props.includeType)
    query = matchDate(query, props.dateFilter)
  
    async function fetchTotal(query) {
      setLoading(true)
      const data = await getData(query, abortController)
      if(!data) return
      let total = data.length ? data[0].total : 0
      setTotal(total)
      setLoading(false)
    }
    fetchTotal(query)

    return () => {
      abortController.abort()
      setPrevTotal(total)
    }

  }, [props, total])
  


  return (
    loading ? <Spinner mask /> :
    <>
      <Tooltip>{t('tooltips.total_users')}</Tooltip>
      <div className="total">
        <div>
          <Icon icon="user" variant="flat" shape="rounded" size="l" color="pink" />
          <span className="number">
            <CountUp
              isCounting
              duration={0.5}
              end={total}
              start={prevTotal}
              formattingFn={num => num.toLocaleString(locale)}
            />
          </span>
        </div>
        <span className="label">
          {total === 1 ? t('total_users') : t('total_users_plural')}
        </span>
      </div>
    </>
  )
}