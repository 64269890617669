import React from 'react'
import PropTypes from 'prop-types'

import './TabNav.scss'


export function TabNav(props) {


  return (
    <>
      <div
        role="tablist"
        className="tab-nav"
      >
        {props.tabs.map(tab => {
          return (
            <button
              role="tab"
              key={tab.value}
              aria-controls={tab.value}
              aria-selected={tab.value === props.activeTab.value}
              className={tab.value === props.activeTab ? 'active' : ''}
              onClick={() => props.setActiveTab(tab.value)}
            >
              <span>{tab.label}</span>
            </button>
          )
        })}
      </div>
      <div
        role="tabpanel"
        tabIndex="0"
      >
        {props.children}
      </div>
    </>
  )
}


TabNav.propTypes = {
  setActiveTab:   PropTypes.func.isRequired,

  tabs:           PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  })).isRequired,

  activeTab:      PropTypes.string.isRequired,
}