import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Doughnut } from 'react-chartjs-2'
import { Spinner, Tooltip, Button, getColor } from '../base'
import { CSVLink } from 'react-csv'

import { getData } from '../util/api'
import { queries } from '../util/queries'
import { matchDepartment, matchItem, matchUser, matchType, matchDate } from '../util/filters'
import { formatDate } from '../util/datetime'


export function OpensPerType(props) {
  
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [csvData, setCsvData] = useState([])
  const [loading, setLoading] = useState(true)
  const [noResults, setNoResults] = useState(false)
  const chartOptions = {
    legend: {
      display: true,
      onClick: null,
      labels: {
        boxWidth: 20
      }
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => data.labels[tooltipItem[0].index],
        label: (tooltipItem, data) => {
          let value = data.datasets[0].data[tooltipItem.index]
          return t(`chartjs.opened${value === 1 ? '' : '_plural'}`, { count: value })
        }
      }
    }
  }
  

  useEffect(() => {
    const abortController = new AbortController()

    let query = queries.opensPerType
    query = matchDepartment(query, props.departmentFilter)
    query = matchUser(query, props.userFilter, props.includeUser)
    query = matchItem(query, props.itemFilter, props.includeItem)
    query = matchType(query, props.typeFilter, props.includeType)
    query = matchDate(query, props.dateFilter)

    async function fetchData(query) {
      setLoading(true)
      const data = await getData(query, abortController)
      if(!data) return
      
      setCsvData(data)
      
      const chartLabels = data.map(item => item.label)
      const chartData = data.map(item => item.value)
      let contentTypes = []
  
      for(let i = 0; i < chartLabels.length; i++) {
        if(chartLabels[i] === 'e-learning')     contentTypes[i] = getColor('blue')
        if(chartLabels[i] === 'course')         contentTypes[i] = getColor('blue')

        if(chartLabels[i] === 'we-learning')    contentTypes[i] = getColor()
        if(chartLabels[i] === 'welearning')     contentTypes[i] = getColor()

        if(chartLabels[i] === 'webinar')        contentTypes[i] = getColor('red')
        if(chartLabels[i] === 'podcast')        contentTypes[i] = getColor('pink')
        if(chartLabels[i] === 'essential')      contentTypes[i] = getColor('orange')
        if(chartLabels[i] === 'scan')           contentTypes[i] = getColor('green')
        if(chartLabels[i] === 'video-feedback') contentTypes[i] = getColor('purple')
      }
  
      if(chartData.length === 0) setNoResults(true)
      else setNoResults(false)
  
      setData({
        labels: chartLabels,
        datasets: [{
          data: chartData,
          backgroundColor: contentTypes
        }]
      })
      setLoading(false)
    }
    fetchData(query)

    return () => abortController.abort()

  }, [props])


  
  const csvHeaders = [
    { key: 'label', label: t('table.type') },
    { key: 'value', label: t('table.opened') }
  ]



  return (
    <>
      <Tooltip>{t('tooltips.opens_per_type')}</Tooltip>
      <h4>{t('titles.opens_per_type')}</h4>
      <div className="chart">
        {noResults ? <span>{t('no_results')}</span> :
          <Doughnut data={data} options={chartOptions} />
        }
      </div>
      <Button label="CSV Export" icon="download" className="csv" size="s" variant="ghost">
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename={`lrs-coursetypes-${formatDate(props.dateFilter.startDate)}-${formatDate(props.dateFilter.endDate)}.csv`}
          className="csv-link"
          separator={';'}
        />
      </Button>
      {loading && <Spinner mask />}
    </>
  )
}