import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { language, locale } from '../Dashboard'

import { formatDateTime, formatDayShort, formatDayLong, formatWeekDay } from './datetime'

import { default as EN } from '../translations/en.json'
import { default as NL } from '../translations/nl.json'


export default i18n
.use(initReactI18next)
.init({
  debug: false,
  fallbackLng: 'en',
  lng: language,
  ns: ['common'],
  defaultNS: 'common',
  interpolation: {
    escapeValue: false,
    format: (value, format, lng) => {
      if(format === 'formatDateTime') return formatDateTime(value)
      else if(format === 'formatDayShort') return formatDayShort(value)
      else if(format === 'formatDayLong') return formatDayLong(value)
      else if(format === 'formatWeekDay') return formatWeekDay(value)
      else if(format === 'toLocaleString') return value.toLocaleString(locale)
      return value
    }
  },
  resources: {
    en: EN,
    nl: NL,
  }
})