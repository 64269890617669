import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { addDays, startOfDay, endOfDay } from 'date-fns'
import { FlexContainer, Column, Row, Card, Spinner, TabNav, getColor, Button } from './base'
import './index.css'
import './util/chartDefaults'
import './Dashboard.scss'

import { getUrlParams } from './util/getUrlParams'
import { getData, lrsData } from './util/api'
import { queries } from './util/queries'
import { formatDayShort, formatDayLong } from './util/datetime'
import { mapDepartments, getDepartmentChildrenIds, listDepartments } from './util/departments'

import { UserFilter } from './filters/UserFilter'
import { ItemFilter } from './filters/ItemFilter'
import { TypeFilter } from './filters/TypeFilter'
import { DatePicker } from './filters/DatePicker'

import { TotalOpens } from './totals/TotalOpens'
import { TotalLogins } from './totals/TotalLogins'
import { TotalActiveUsers } from './totals/TotalActiveUsers'
import { TotalCertificates } from './totals/TotalCertificates'
import { TotalAverageScore } from './totals/TotalAverageScore'

import { OpensPerType } from './cards/OpensPerType'
import { OpensPerItem } from './cards/OpensPerItem'
import { LoginsPerDay } from './cards/LoginsPerDay'
import { LoginsPerWeekDay } from './cards/LoginsPerWeekDay'
import { LoginsPerHour } from './cards/LoginsPerHour'
import { MostActiveUsers } from './cards/MostActiveUsers'
import { CertificatesPerUser } from './cards/CertificatesPerUser'
import { UserActivity } from './cards/UserActivity'
import { AverageAttemptsPerExam } from './cards/AverageAttemptsPerExam'
import { ExamStatistics } from './cards/ExamStatistics'


export const token = getUrlParams('lrstoken') || process.env.REACT_APP_LRS_TOKEN
export const language = getUrlParams('language') || process.env.REACT_APP_LANGUAGE
export const locale = language === 'nl-NL' ? 'nl-NL' : 'en-GB'


export function Dashboard() {

  const { t } = useTranslation()

  const tabs = [
    { label: t('tab_courses'), value: 'courses' },
    { label: t('tab_logins'), value: 'logins' },
    { label: t('tab_users'), value: 'users' },
    { label: t('tab_exams'), value: 'exams' }
  ].filter(item => item.value !== 'exams' || lrsData.historical)

  let buttonLabel = t('select_date_range')

  const [ loading, setLoading ] = useState(true)
  const [ activeTab, setActiveTab ] = useState(tabs[0].value)
  const [ firstTimestamp, setFirstTimestamp ] = useState()
  const [ responseDepartments, setResponseDepartments ] = useState()
  const [ allDepartments, setAllDepartments ] = useState()
  const [ selectedDepartment, setSelectedDepartment ] = useState([])
  const [ hideDepartments, setHideDepartments ] = useState(false)
  const [ departmentFilter, setDepartmentFilter ] = useState([])
  const [ includeUser, setIncludeUser ] = useState(true)
  const [ includeItem, setIncludeItem ] = useState(true)
  const [ includeType, setIncludeType ] = useState(true)
  const [ userFilter, setUserFilter ] = useState([])
  const [ itemFilter, setItemFilter ] = useState([])
  const [ typeFilter, setTypeFilter ] = useState([])
  const [ showDatePicker, setShowDatePicker ] = useState(false)

  const [ datePicker, setDatePicker ] = useState({
    selection: {
      startDate: startOfDay(addDays(new Date(), -14)),
      endDate: endOfDay(new Date()),
      key: 'selection',
      color: getColor()
    }
  })

  const [ dateFilter, setDateFilter ] = useState({
    startDate: startOfDay(addDays(new Date(), -14)),
    endDate: endOfDay(new Date())
  })

  const formattedDateRange = `${formatDayShort(dateFilter.startDate)} - ${formatDayShort(dateFilter.endDate)}`

  useEffect(() => {
    setLoading(true)

    if(lrsData.historical) {
      window.parent.postMessage({
      action: 'request-admin-data'
    }, "*");

    window.addEventListener("message", (event) => {
      if(event.data.request && event.data.request === 'request-admin-data') {
        setResponseDepartments(mapDepartments(event));
      }
    }, false);

    } else {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    if(responseDepartments?.[0]) {
      setAllDepartments(listDepartments(responseDepartments)) // save all departments
      setDepartmentFilter([]) // clear filter to improve query performance
      setSelectedDepartment(responseDepartments[0].item)
    } else {
      setHideDepartments(true);
    }
    setLoading(false)
  }, [responseDepartments]);

  useEffect(() => {
    setHideDepartments(false);
  }, [selectedDepartment]);

  useEffect(() => {

    async function getFirstActivity() {
      const data = await getData(queries.firstStatement)
      let firstStatement = data.map(item => item.timestamp)
      setFirstTimestamp(new Date(firstStatement[0]))
    }
    getFirstActivity()

  }, []) // this effect runs once on initial render



  async function handleDepartmentSelection(selection) {
    if(selection === selectedDepartment) return // selecting the same option

    setSelectedDepartment(selection) // show selected department in UI
    setUserFilter([]) // clear user filter
    setDepartmentFilter(getDepartmentChildrenIds(responseDepartments, selection.id));
  }


  function handleIncludeUser() {
    setIncludeUser(!includeUser)
  }
  function handleIncludeItem() {
    setIncludeItem(!includeItem)
  }
  function handleIncludeType() {
    setIncludeType(!includeType)
  }


  function handleUserSelection(selection) {
    if(selection === null) selection = []
    setUserFilter(selection)
  }
  function handleItemSelection(selection) {
    if(selection === null) selection = []
    setItemFilter(selection)
  }
  function handleTypeSelection(selection) {
    if(selection === null) selection = []
    setTypeFilter(selection)
  }


  function handleDatePicker(object) {
    setDatePicker({ ...datePicker, ...object })
    buttonLabel = 'Click to apply filter'
  }

  function handleShowDatePicker() {
    if(showDatePicker) {
      setDateFilter({
        startDate: startOfDay(datePicker.selection.startDate),
        endDate: endOfDay(datePicker.selection.endDate)
      })
      setShowDatePicker(false)
      buttonLabel = 'Choose a date range'
    }
    else setShowDatePicker(true)
  }






  if(!token) return null

  return (
    <div className="lrs-dashboard">

      <header>
        <div className="left">
          <h1>Learning Record Store</h1>
          <p>{t('active_since')}: {firstTimestamp ? `${formatDayLong(firstTimestamp)}` : '-'}</p>
        </div>
      </header>



      <div className="filter">

        { lrsData.historical && !hideDepartments &&
        <Select
          styles={{
            control: (base, state) => ({
              ...base,
              boxShadow: 'none',
              borderColor: state.isFocused ? getColor() : getColor('grey-light'),
              '&:hover': { borderColor: state.isFocused ? getColor() : getColor('grey-dark') }
            })
          }}
          placeholder="Loading..."
          options={allDepartments}
          onChange={handleDepartmentSelection}
          value={selectedDepartment}
          components={{ IndicatorSeparator: () => null }}
        />
        }

        <div className="flex-parent">

          <div className="col">
            <div className="include-exclude">
              <Button size="s" label="incl" variant={includeUser ? 'flat' : 'secondary'} onClick={handleIncludeUser} />
              <Button size="s" label="excl" variant={includeUser ? 'secondary' : 'flat'} onClick={handleIncludeUser} />
            </div>
            <div className="multi-select-container">
              <UserFilter
                onChange={handleUserSelection}
                value={userFilter}
                departmentFilter={departmentFilter}
                itemFilter={itemFilter}
                typeFilter={typeFilter}
                dateFilter={dateFilter}
                includeItem={includeItem}
                includeType={includeType}
              />
            </div>
          </div>

          <div className="col">
            <div className="include-exclude">
              <Button size="s" label="incl" variant={includeItem ? 'flat' : 'secondary'} onClick={handleIncludeItem} />
              <Button size="s" label="excl" variant={includeItem ? 'secondary' : 'flat'} onClick={handleIncludeItem} />
            </div>
            <div className="multi-select-container">
              <ItemFilter
                onChange={handleItemSelection}
                value={itemFilter}
                departmentFilter={departmentFilter}
                userFilter={userFilter}
                typeFilter={typeFilter}
                dateFilter={dateFilter}
                includeUser={includeUser}
                includeType={includeType}
              />
            </div>
          </div>

          <div className="col">
            <div className="include-exclude">
              <Button size="s" label="incl" variant={includeType ? 'flat' : 'secondary'} onClick={handleIncludeType} />
              <Button size="s" label="excl" variant={includeType ? 'secondary' : 'flat'} onClick={handleIncludeType} />
            </div>
            <div className="multi-select-container">
              <TypeFilter
                onChange={handleTypeSelection}
                value={typeFilter}
                departmentFilter={departmentFilter}
                userFilter={userFilter}
                itemFilter={itemFilter}
                dateFilter={dateFilter}
                includeUser={includeUser}
                includeItem={includeItem}
              />
            </div>
          </div>

        </div>

      </div>



      <div className="divider" />



      <div className="tab-nav-container">
        <TabNav
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />

        <Button
          size="xl" icon="date" className="datepicker"
          label={showDatePicker ? buttonLabel : formattedDateRange}
          onClick={handleShowDatePicker}
        />

        <DatePicker
          show={showDatePicker}
          firstTimestamp={firstTimestamp}
          onChange={handleDatePicker}
          ranges={[datePicker.selection]}
          language={language}
        />
      </div>






      {activeTab === tabs[0].value && <FlexContainer variant="custom-width">

        <Column size="20">
          <Card className="total">
            {loading ? <Spinner mask /> :
            <TotalOpens
              departmentFilter={departmentFilter}
              userFilter={userFilter}
              itemFilter={itemFilter}
              typeFilter={typeFilter}
              dateFilter={dateFilter}
              includeUser={includeUser}
              includeItem={includeItem}
              includeType={includeType}
            />}
          </Card>
        </Column>

        <Column size="40">
          <Card>
            {loading ? <Spinner mask /> :
            <OpensPerItem
              departmentFilter={departmentFilter}
              userFilter={userFilter}
              itemFilter={itemFilter}
              typeFilter={typeFilter}
              dateFilter={dateFilter}
              includeUser={includeUser}
              includeItem={includeItem}
              includeType={includeType}
            />}
          </Card>
        </Column>

        <Column size="40">
          <Card className="coursetype">
            {loading ? <Spinner mask /> :
            <OpensPerType
              departmentFilter={departmentFilter}
              userFilter={userFilter}
              itemFilter={itemFilter}
              typeFilter={typeFilter}
              dateFilter={dateFilter}
              includeUser={includeUser}
              includeItem={includeItem}
              includeType={includeType}
            />}
          </Card>
          </Column>

      </FlexContainer>}



      {activeTab === tabs[1].value && <FlexContainer variant="custom-width">
        <Row>
          <Column size="20">
            <Card className="total">
            {loading ? <Spinner mask /> :
              <TotalLogins
                departmentFilter={departmentFilter}
                userFilter={userFilter}
                itemFilter={itemFilter}
                typeFilter={typeFilter}
                dateFilter={dateFilter}
                includeUser={includeUser}
                includeItem={includeItem}
                includeType={includeType}
              />}
            </Card>
          </Column>

          <Column size="80">
            <Card>
            {loading ? <Spinner mask /> :
              <LoginsPerDay
                departmentFilter={departmentFilter}
                userFilter={userFilter}
                itemFilter={itemFilter}
                typeFilter={typeFilter}
                dateFilter={dateFilter}
                includeUser={includeUser}
                includeItem={includeItem}
                includeType={includeType}
              />}
            </Card>
          </Column>
        </Row>

        <Row justifyContent="flex-end">

          <Column size="40">
            <Card>
            {loading ? <Spinner mask /> :
              <LoginsPerWeekDay
                departmentFilter={departmentFilter}
                userFilter={userFilter}
                itemFilter={itemFilter}
                typeFilter={typeFilter}
                dateFilter={dateFilter}
                includeUser={includeUser}
                includeItem={includeItem}
                includeType={includeType}
                language={language}
              />}
            </Card>
          </Column>

          <Column size="40">
            <Card>
            {loading ? <Spinner mask /> :
              <LoginsPerHour
                departmentFilter={departmentFilter}
                userFilter={userFilter}
                itemFilter={itemFilter}
                typeFilter={typeFilter}
                dateFilter={dateFilter}
                includeUser={includeUser}
                includeItem={includeItem}
                includeType={includeType}
              />}
            </Card>
          </Column>
        </Row>

      </FlexContainer>}



      {activeTab === tabs[2].value && <FlexContainer variant="custom-width">
        <Row>
          <Column size="20">
            <Card className="total">
              {loading ? <Spinner mask /> :
              <TotalActiveUsers
                departmentFilter={departmentFilter}
                userFilter={userFilter}
                itemFilter={itemFilter}
                typeFilter={typeFilter}
                dateFilter={dateFilter}
                includeUser={includeUser}
                includeItem={includeItem}
                includeType={includeType}
              />}
            </Card>
          </Column>

          <Column size="40">
            <Card>
            {loading ? <Spinner mask /> :
              <MostActiveUsers
                departmentFilter={departmentFilter}
                userFilter={userFilter}
                itemFilter={itemFilter}
                typeFilter={typeFilter}
                dateFilter={dateFilter}
                includeUser={includeUser}
                includeItem={includeItem}
                includeType={includeType}
              />}
            </Card>
          </Column>

          <Column size="40">
            <Card>
            {loading ? <Spinner mask /> :
              <CertificatesPerUser
                departmentFilter={departmentFilter}
                userFilter={userFilter}
                itemFilter={itemFilter}
                typeFilter={typeFilter}
                dateFilter={dateFilter}
                includeUser={includeUser}
                includeItem={includeItem}
                includeType={includeType}
              />}
            </Card>
          </Column>
        </Row>

        <Row justifyContent="flex-end">
          <Column size="80">
            <Card className="table">
              {loading ? <Spinner mask /> :
              <UserActivity
                departmentFilter={departmentFilter}
                userFilter={userFilter}
                itemFilter={itemFilter}
                typeFilter={typeFilter}
                dateFilter={dateFilter}
                includeUser={includeUser}
                includeItem={includeItem}
                includeType={includeType}
              />}
            </Card>
          </Column>
        </Row>

      </FlexContainer>}



      {lrsData.historical && activeTab === tabs[3].value && <FlexContainer variant="custom-width">

        <Column size="20">

          <Column>
            <Card className="total">
              {loading ? <Spinner mask /> :
              <TotalAverageScore
                departmentFilter={departmentFilter}
                userFilter={userFilter}
                itemFilter={itemFilter}
                typeFilter={typeFilter}
                dateFilter={dateFilter}
                includeUser={includeUser}
                includeItem={includeItem}
                includeType={includeType}
              />}
            </Card>
          </Column>

          <Column>
            <Card className="total">
              {loading ? <Spinner mask /> :
              <TotalCertificates
                departmentFilter={departmentFilter}
                userFilter={userFilter}
                itemFilter={itemFilter}
                typeFilter={typeFilter}
                dateFilter={dateFilter}
                includeUser={includeUser}
                includeItem={includeItem}
                includeType={includeType}
              />}
            </Card>
          </Column>

        </Column>

        <Column size="80">
          <Column>
            <Card>
              {loading ? <Spinner mask /> :
              <AverageAttemptsPerExam
                departmentFilter={departmentFilter}
                userFilter={userFilter}
                itemFilter={itemFilter}
                typeFilter={typeFilter}
                dateFilter={dateFilter}
                includeUser={includeUser}
                includeItem={includeItem}
                includeType={includeType}
              />}
            </Card>
          </Column>
          <Column>
            <Card className="table">
              {loading ? <Spinner mask /> :
              <ExamStatistics
                departmentFilter={departmentFilter}
                userFilter={userFilter}
                itemFilter={itemFilter}
                typeFilter={typeFilter}
                dateFilter={dateFilter}
                includeUser={includeUser}
                includeItem={includeItem}
                includeType={includeType}
              />}
            </Card>
          </Column>
        </Column>

      </FlexContainer>}

    </div>
  )
}