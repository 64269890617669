import { lrsData } from './api'
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone


export function loginsPerDayQuery() {
  return [
    {
      '$match': {
        'statement.verb.id': {
          '$eq': `${lrsData.string}/verb/loggedin`
        }
      }
    },
    {
      '$project': {
        'date': {
          '$dateToString': {
            'date': '$timestamp',
            'format': '%Y-%m-%d',
            'timezone': timezone
          }
        },
        'user': '$statement.actor.account.name'
      }
    },
    {
      '$group': {
        '_id': {
          'date': '$date',
          'user': '$user'
        },
      }
    },
    {
      '$group': {
        '_id': '$_id.date',
        'value': {
          '$sum': 1
        },
      }
    },
    {
      '$sort': {
        '_id': 1
      }
    },
    {
      '$project': {
        '_id': 0,
        'date': '$_id',
        'value': 1
      }
    }
  ]
}


export function loginsPerWeekDayQuery() {
  return [
    {
      '$match': {
        'statement.verb.id': {
          '$eq': `${lrsData.string}/verb/loggedin`
        }
      }
    },
    {
      '$project': {
        'group': {
          '$isoDayOfWeek': {
            'date': '$timestamp',
            'timezone': timezone
          }
        },
        'user': '$statement.actor.account.name'
      }
    },
    {
      '$group': {
        '_id': {
          'weekday': '$group',
          'user': '$user'
        }
      }
    },
    {
      '$group': {
        '_id': '$_id.weekday',
        'value': {
          '$sum': 1
        }
      }
    },
    {
      '$sort': {
        '_id': 1
      }
    },
    {
      '$project': {
        '_id': 0,
        'weekday': '$_id',
        'value': 1
      }
    }
  ]
}



export function loginsPerHourQuery() {
  return [
    {
      '$match': {
        'statement.verb.id': {
          '$eq': `${lrsData.string}/verb/loggedin`
        }
      }
    },
    {
      '$project': {
        'hour': {
          '$hour': {
            'date': '$timestamp',
            'timezone': timezone
          }
        },
        'user': '$statement.actor.account.name'
      }
    },
    {
      '$group': {
        '_id': {
          'hour': '$hour',
          'user': '$user'
        }
      }
    },
    {
      '$group': {
        '_id': '$_id.hour',
        'value': {
          '$sum': 1
        }
      }
    },
    {
      '$sort': {
        '_id': 1
      }
    },
    {
      '$project': {
        '_id': 0,
        'hour': '$_id',
        'value': 1
      }
    }
  ]
}