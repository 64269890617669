import React from 'react'
import PropTypes from 'prop-types'

import './Card.scss'


export function Card(props) {
  return (
    <article
      className={`card
        ${props.className ? props.className : ''}
      `}
      id={props.id}
      aria-labelledby={props.ariaLabelledBy}
      aria-describedby={props.ariaDescribedBy}
    >
      {props.children}
    </article>
  )
}


Card.propTypes = {
  id:               PropTypes.string,
  ariaLabelledBy:   PropTypes.string,
  ariaDescribedBy:  PropTypes.string,
  children:         PropTypes.any,
  className:        PropTypes.string,
}