import React from 'react'
import PropTypes from 'prop-types'

import './Flex.scss'


export function FlexContainer(props) {
  return (
    <div
      className={`flex-container
        variant-${props.variant}
        column-size-${props.columnSize}
        gutter-size-${props.gutterSize}
        align-items-${props.alignItems}
        justify-content-${props.justifyContent}
        ${props.responsive ? 'responsive' : ''}
        ${props.className ? props.className : ''}
      `}
      id={props.id}
    >
      {props.children}
    </div>
  )
}


export function Row(props) {
  return (
    <div className={`row
      align-items-${props.alignItems}
      justify-content-${props.justifyContent}
      ${props.className ? props.className : ''}
    `}>
      {props.children}
    </div>
  )
}


export function Column(props) {
  return (
    <div className={`column
      ${props.size ? `size-${props.size}` : ''}
      ${props.className ? props.className : ''}
    `}>
      {props.children}
    </div>
  )
}


FlexContainer.propTypes = {
  responsive:     PropTypes.bool,
  variant:        PropTypes.oneOf(['fluid-width', 'max-width', 'auto-width', 'custom-width']),
  columnSize:     PropTypes.oneOf(['s', 'm', 'l']),
  gutterSize:     PropTypes.oneOf(['s', 'm', 'l']),
  alignItems:     PropTypes.oneOf(['stretch', 'center', 'flex-start', 'flex-end']),
  justifyContent: PropTypes.oneOf(['center', 'flex-start', 'flex-end', 'space-between', 'space-around', 'space-evenly']),
  id:             PropTypes.string,
  children:       PropTypes.any,
  className:      PropTypes.string,
}

FlexContainer.defaultProps = {
  responsive:     true,
  variant:        'fluid-width',
  columnSize:     'm',
  gutterSize:     'm',
  justifyContent: 'flex-start',
  alignItems:     'flex-start',
}


Row.propTypes = {
  alignItems:     PropTypes.oneOf(['stretch', 'center', 'flex-start', 'flex-end']),
  justifyContent: PropTypes.oneOf(['center', 'flex-start', 'flex-end', 'space-between', 'space-around', 'space-evenly']),
  children:       PropTypes.any,
  className:      PropTypes.string,
}

Row.defaultProps = {
  justifyContent: 'flex-start',
  alignItems:     'flex-start',
}


Column.propTypes = {
  size:       PropTypes.oneOf(['10', '20', '30', '40', '50', '60', '70', '80', '90', '100', 'full-width']),
  children:   PropTypes.any,
  className:  PropTypes.string,
}