import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CSVLink } from 'react-csv'
import { UserActivityTable } from '../tables/UserActivityTable'
import { Spinner, Tooltip, Button } from '../base'

import { getData } from '../util/api'
import { queries } from '../util/queries'
import { matchDepartment, matchItem, matchUser, matchType, matchDate } from '../util/filters'
import { formatDate } from '../util/datetime'


export function UserActivity(props) {

  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [noResults, setNoResults] = useState(false)


  useEffect(() => {
    const abortController = new AbortController()

    let query = queries.userActivity
    query = matchDepartment(query, props.departmentFilter)
    query = matchUser(query, props.userFilter, props.includeUser)
    query = matchItem(query, props.itemFilter, props.includeItem)
    query = matchType(query, props.typeFilter, props.includeType)
    query = matchDate(query, props.dateFilter)

    async function fetchData(query) {
      setLoading(true)

      let data = await getData(query, abortController)
      if(!data) return

      if(data.length === 0) setNoResults(true)
      else setNoResults(false)

      data.forEach(element => {
        element.progress = Number(element.progress).toFixed(0)
        element.score = element.score ? Number(element.score).toFixed(1) : ''
      })

      setData(data)
      setLoading(false)
    }
    fetchData(query)

    return () => abortController.abort()

  }, [props])



  const memoData = useMemo(() => data, [data])


  const tableColumns = useMemo(() => [
    { accessor: 'user_name', Header: t('table.user') },
    { accessor: 'course_type', Header: t('table.type') },
    { accessor: 'course_label', Header: t('table.item') },
    { accessor: 'progress', Header: t('table.progress') },
    { accessor: 'score', Header: t('table.score') },
    { accessor: 'min_timestamp', Header: t('table.mindate') },
    { accessor: 'max_timestamp', Header: t('table.maxdate') }
  ], [t])

  const csvHeaders = [
    { key: 'user_id', label: t('table.uid') },
    { key: 'user_name', label: t('table.user') },
    { key: 'course_id', label: t('table.cid') },
    { key: 'course_label', label: t('table.item') },
    { key: 'course_type', label: t('table.type') },
    { key: 'progress', label: t('table.progress') },
    { key: 'score', label: t('table.score') }
  ]
  if(JSON.stringify(props.dateFilter.startDate) === JSON.stringify(new Date('2020/1/1'))) {
    csvHeaders.push({ key: 'min_timestamp', label: t('table.mindate') })
  }
  csvHeaders.push({ key: 'max_timestamp', label: t('table.maxdate') })

  return (
    <>
      <Tooltip>{t('tooltips.user_activity')}</Tooltip>
      <h4>{t('titles.user_activity')}</h4>
      {noResults ? <span>{t('no_results')}</span> :
        <div className="data-table">
          <UserActivityTable
            data={memoData}
            columns={tableColumns}
            className={
              JSON.stringify(props.dateFilter.startDate) === JSON.stringify(new Date('2020/1/1'))
                ? 'show-mindate'
                : null
            }
          />
          <Button label="CSV Export" icon="download" className="csv" size="s" variant="ghost">
            <CSVLink
              data={memoData}
              headers={csvHeaders}
              filename={`lrs-user-activity-${formatDate(props.dateFilter.startDate)}-${formatDate(props.dateFilter.endDate)}.csv`}
              className="csv-link"
              separator={';'}
            />
          </Button>
        </div>
      }
      {loading && <Spinner mask />}
    </>
  )
}