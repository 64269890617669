import { lrsData } from './api'
export const queries = {

  firstStatement: [
    {
      '$sort': {
        'timestamp': 1
      }
    },
    {
      '$limit': 1
    },
    {
      '$project': {
        '_id': 0,
        'timestamp': 1
      }
    }
  ],



  allUsers: [
    {
      '$match': {
        'statement.verb.id': {
          '$eq': `${lrsData.string}/verb/opened`
        }
      }
    },
    {
      '$group': {
        '_id': '$statement.actor.account.name',
        'label': {
          '$first': '$statement.actor.name'
        }
      }
    },
    {
      '$sort': {
        'label': 1
      }
    },
    {
      '$project': {
        '_id': 0,
        'value': '$_id',
        'label': 1
      }
    }
  ],



  allItems: [
    {
      '$match': {
        'statement.verb.id': {
          '$eq': `${lrsData.string}/verb/opened`
        }
      }
    },
    {
      '$group': {
        '_id': `$statement.object.definition.extensions.${lrsData.selector}/course/id`,
        'label': {
          '$first': `$statement.object.definition.extensions.${lrsData.selector}/course/name`
        }
      }
    },
    {
      '$project': {
        '_id': 0,
        'value': '$_id',
        'label': 1
      }
    }
  ],



  allTypes: [
    {
      '$match': {
        'statement.verb.id': {
          '$eq': `${lrsData.string}/verb/opened`
        }
      }
    },
    {
      '$group': {
        '_id': `$statement.object.definition.extensions.${lrsData.selector}/course/type`,
        'label': {
          '$first': `$statement.object.definition.extensions.${lrsData.selector}/course/type`
        }
      }
    },
    {
      '$sort': {
        'label': 1
      }
    },
    {
      '$project': {
        '_id': 0,
        'value': '$_id',
        'label': 1
      }
    }
  ],



  totalLogins: [
    {
      '$match': {
        'statement.verb.id': {
          '$eq': `${lrsData.string}/verb/loggedin`
        }
      }
    },
    {
      '$group': {
        '_id': '$statement.actor.account.name'
      }
    },
    {
      '$count': 'total'
    }
  ],



  totalOpens: [
    {
      '$match': {
        'statement.verb.id': {
          '$eq': `${lrsData.string}/verb/opened`
        }
      }
    },
    {
      '$count': 'total'
    }
  ],



  totalActiveUsers: [
    {
      '$match': {
        'statement.verb.id': {
          '$eq': `${lrsData.string}/verb/opened`
        }
      }
    },
    {
      '$group': {
        '_id': '$statement.actor.account.name'
      }
    },
    {
      '$count': 'total'
    }
  ],



  totalCertificates: [
    {
      '$match': {
        'statement.verb.id': {
          '$eq': `${lrsData.string}/verb/earned`
        }
      }
    },
    {
      '$count': 'total'
    }
  ],



  totalAverageScore: [
    {
      '$match': {
        'statement.verb.id': {
          '$eq': `${lrsData.string}/verb/scored`
        }
      }
    },
    {
      '$group': {
        '_id': null,
        'score': {
          '$avg': '$statement.result.score.scaled'
        }
      },
    },
    {
      '$project': {
        '_id': 0,
        'score': {
          '$multiply': ['$score', 10]
        }
      }
    }
  ],



  opensPerType: [
    {
      '$match': {
        'statement.verb.id': {
          '$eq': `${lrsData.string}/verb/opened`
        }
      }
    },
    {
      '$group': {
        '_id': `$statement.object.definition.extensions.${lrsData.selector}/course/type`,
        'label': {
          '$first': `$statement.object.definition.extensions.${lrsData.selector}/course/type`,
        },
        'value': {
          '$sum': 1
        }
      }
    },
    {
      '$sort': {
        'value': -1
      }
    },
    {
      '$project': {
        '_id': 0,
        'label': '$_id',
        'value': 1
      }
    }
  ],



  opensPerItem: [
    {
      '$match': {
        'statement.verb.id': {
          '$eq': `${lrsData.string}/verb/opened`
        }
      }
    },
    {
      '$group': {
        '_id': `$statement.object.definition.extensions.${lrsData.selector}/course/id`,
        'label': {
          '$first': `$statement.object.definition.extensions.${lrsData.selector}/course/name`,
        },
        'type': {
          '$first': `$statement.object.definition.extensions.${lrsData.selector}/course/type`,
        },
        'value': {
          '$sum': 1
        }
      }
    },
    {
      '$sort': {
        'value': -1
      }
    },
    {
      '$project': {
        '_id': 1,
        'label': 1,
        'type': 1,
        'value': 1
      }
    }
  ],



  certificatesPerUser: [
    {
      '$match': {
        'statement.verb.id': {
          '$eq': `${lrsData.string}/verb/earned`
        }
      }
    },
    {
      '$group': {
        '_id': '$statement.actor.account.name',
        'value': {
          '$sum': 1
        },
        'label': {
          '$first': '$statement.actor.name'
        }
      }
    },
    {
      '$sort': {
        'value': -1
      }
    },
    {
      '$project': {
        '_id': 1,
        'value': 1,
        'label': 1
      }
    }
  ],



  mostActiveUsers: [
    {
      '$match': {
        'statement.verb.id': {
          '$eq': `${lrsData.string}/verb/opened`
        }
      }
    },
    {
      '$group': {
        '_id': '$statement.actor.account.name',
        'user_name': {
          '$first': '$statement.actor.name'
        },
        'value': {
          '$sum': 1
        }
      }
    },
    {
      '$sort': {
        'value': -1
      }
    },
    {
      '$project': {
        '_id': 1,
        'value': 1,
        'label': '$user_name'
      }
    }
  ],



  averageAttemptsPerExam: [
    {
      '$match': {
        'statement.verb.id': {
          '$eq': `${lrsData.string}/verb/attempted`
        }
      }
    },
    {
      '$group': {
        '_id': {
          'user': '$statement.actor.account.name',
          'course': `$statement.object.definition.extensions.${lrsData.selector}/course/id`,
          'label': `$statement.object.definition.extensions.${lrsData.selector}/course/name`,
          'type': `$statement.object.definition.extensions.${lrsData.selector}/course/type`,
        },
        'attempts': {
          '$sum': 1
        }
      }
    },
    {
      '$group': {
        '_id': '$_id.course',
        'label': {
          '$first': '$_id.label'
        },
        'type': {
          '$first': '$_id.type'
        },
        'users': {
          '$sum': 1
        },
        'totalAttempts': {
          '$sum': '$attempts'
        },
        'average': {
          '$avg': '$attempts'
        }
      }
    },
    {
      '$sort': {
        'average': -1
      }
    },
    {
      '$project': {
        '_id': 1,
        'label': 1,
        'type': 1,
        'attempts': '$totalAttempts',
        'users': 1,
        'average': 1
      }
    }
  ],



  examStatistics: [
    {
      '$match': {
        [`statement.object.definition.extensions.${lrsData.selector}/test/id`]: {
          '$exists': true
        }
      }
    },
    {
      '$group': {
        '_id': `$statement.object.definition.extensions.${lrsData.selector}/course/id`,
        'type': {
          '$first': `$statement.object.definition.extensions.${lrsData.selector}/course/type`
        },
        'label': {
          '$first': `$statement.object.definition.extensions.${lrsData.selector}/course/name`
        },
        'attempts': {
          '$sum': {
            '$cond': [ { '$eq': ['$statement.verb.id', `${lrsData.string}/verb/attempted`] }, 1, 0]
          }
        },
        'completed': {
          '$sum': {
            '$cond': [ { '$eq': ['$statement.verb.id', `${lrsData.string}/verb/scored`] }, 1, 0]
          }
        },
        'certificates': {
          '$sum': {
            '$cond': [{ '$eq': ['$statement.verb.id', `${lrsData.string}/verb/earned`] }, 1, 0]
          }
        },
        'score': {
          '$avg': {
            '$cond': [{ '$eq': ['$statement.verb.id', `${lrsData.string}/verb/scored`] }, '$statement.result.score.scaled', null]
          }
        }
      }
    },
    {
      '$sort': {
        'attempts': -1
      }
    },
    {
      '$project': {
        '_id': 1,
        'type': 1,
        'label': 1,
        'attempts': 1,
        'completed': 1,
        'certificates': 1,
        'score': {
          '$multiply': ['$score', 10]
        }
      }
    }
  ],



  questionStatistics: [
    {
      '$match': {
        [`statement.object.definition.extensions.${lrsData.selector}/test/id`]: {
          '$exists': true
        },
        [`statement.object.definition.extensions.${lrsData.selector}/question/id`]: {
          '$exists': true
        },
      }
    },
    {
      '$group': {
        '_id': `$statement.object.definition.extensions.${lrsData.selector}/question/id`,
        'label': {
          '$first': `$statement.object.definition.extensions.${lrsData.selector}/question/name`
        },
        'total': {
          '$sum': 1
        },
        'correct': {
          '$sum': {
            '$cond': ['$statement.result.success', 1, 0]
          }
        },
        'wrong': {
          '$sum': {
            '$cond': ['$statement.result.success', 0, 1]
          }
        },
      }
    },
    {
      '$project': {
        '_id': 1,
        'label': 1,
        'correct': 1,
        'wrong': 1,
        'total': 1,
        'score': {
          '$multiply': [{'$divide': ['$correct', '$total']}, 100]
        }
      }
    },
    {
      '$sort': {
        'score': -1
      }
    }
  ],



  questionAnswers: [
    {
      '$group': {
        '_id': `$statement.object.definition.extensions.${lrsData.selector}/question/id`,
        'label': {
          '$first': `$statement.object.definition.extensions.${lrsData.selector}/question/name`
        },
        'answerType': {
          '$first': '$statement.object.definition.interactionType'
        },
        'answers': {
          '$first': '$statement.object.definition.choices'
        },
        'correctAnswers': {
          '$first': '$statement.object.definition.correctResponsesPattern'
        }
      }
    },
    {
      '$project': {
        '_id': 1,
        'label': 1,
        'answerType': 1,
        'answers': 1,
        'correctAnswers': 1
      }
    }
  ],



  userActivity: [
    {
      '$match': {
        [`statement.object.definition.extensions.${lrsData.selector}/module/id`]: {
          '$exists': false
        },
        [`statement.object.definition.extensions.${lrsData.selector}/question/id`]: {
          '$exists': false
        },
        'statement.verb.id': {
          '$nin': [
            `${lrsData.string}/verb/loggedin`,
            `${lrsData.string}/verb/launched`,
            `${lrsData.string}/verb/scored`
          ]
        }
      }
    },
    {
      '$group': {
        '_id': {
          'user_id': '$statement.actor.account.name',
          'course_id': `$statement.object.definition.extensions.${lrsData.selector}/course/id`
        },
        'user_name': {
          '$first': '$statement.actor.name'
        },
        'type': {
          '$first': `$statement.object.definition.extensions.${lrsData.selector}/course/type`
        },
        'course_label': {
          '$first': `$statement.object.definition.extensions.${lrsData.selector}/course/name`
        },
        'max_progress': {
          '$max': `$statement.result.extensions.${lrsData.selector}/progress`
        },
        "verbs": {
            '$push': '$statement.verb.id'
         },
        'max_score': {
          '$max': '$statement.result.score.scaled'
        },
        'min_timestamp': {
          '$min': '$timestamp'
        },
        'max_timestamp': {
          '$max': '$timestamp'
        }
      }
    },
    {
      '$sort': {
        'max_timestamp': -1
      }
    },
    {
      '$project': {
        '_id': 0,
        'user_id':        '$_id.user_id',
        'user_name':      '$user_name',
        'course_id':      '$_id.course_id',
        'course_type':    '$type',
        'course_label':   '$course_label',
        'min_timestamp':  '$min_timestamp',
        'max_timestamp':  '$max_timestamp',
        'progress': {
          '$cond': [
            {'$in': [`${lrsData.string}/verb/completed`, '$verbs']},
            100,
            { '$multiply': [ '$max_progress', 100 ] }
          ]
        },
        'score': {
          '$multiply': ['$max_score', 10]
        }
      }
    }
  ]

}