import { lrsData } from './api'

export function matchDepartment(query, filter) {
  if(!filter || filter.length === 0) return query

  filter = filter.map(item => `${item}`)
    
  const match = [{
    '$match': {
      [`statement.object.definition.extensions.${lrsData.selector}/department/id`]: {
        '$in': filter
      }
    }
  }]
  return query = [...match, ...query]
}



export function matchUser(query, filter, include = true) {
  if(!filter || filter.length === 0) return query
  
  let selection = []
  for(let i = 0; i < filter.length; i++) {
    selection.push(filter[i].value)
  }

  let match = [{
    '$match': {
      'statement.actor.account.name': {
        '$in': selection
      }
    }
  }]
  if(include === false) {
    match = [{
      '$match': {
        'statement.actor.account.name': {
          '$nin': selection
        }
      }
    }]
  }
  return query = [...match, ...query]
}



export function matchItem(query, filter, include = true) {
  if(!filter || filter.length === 0) return query
  
  let selection = []
  for(let i = 0; i < filter.length; i++) {
    selection.push(filter[i].value)
  }

  let match = [{
    '$match': {
      [`statement.object.definition.extensions.${lrsData.selector}/course/id`]: {
        '$exists': true,
        '$in': selection
      },
      [`statement.object.definition.extensions.${lrsData.selector}/activity/type`]: {
        '$ne': 'test'
      }
    }
  }]
  if(include === false) {
    match = [{
      '$match': {
        [`statement.object.definition.extensions.${lrsData.selector}/course/id`]: {
          '$exists': true,
          '$nin': selection
        },
        [`statement.object.definition.extensions.${lrsData.selector}/activity/type`]: {
          '$ne': 'test'
        }
      }
    }]
  }
  return query = [...match, ...query]
}



export function matchType(query, filter, include = true) {
  if(!filter || filter.length === 0) return query
  
  let selection = []
  for(let i = 0; i < filter.length; i++) {
    selection.push(filter[i].value)
  }

  let match = [{
    '$match': {
      [`statement.object.definition.extensions.${lrsData.selector}/course/type`]: {
        '$in': selection
      }
    }
  }]
  if(include === false ) {
    match = [{
      '$match': {
        [`statement.object.definition.extensions.${lrsData.selector}/course/type`]: {
          '$nin': selection
        }
      }
    }]
  }
  return query = [...match, ...query]
}



export function matchDate(query, filter) {
  if(!filter || !filter.startDate || !filter.endDate) return query

  const match = [{
    '$match': {
      'timestamp': {
        '$gte': {
          '$dte': filter.startDate
        },
        '$lte': {
          '$dte': filter.endDate
        }
      }
    }
  }]
  return query = [...match, ...query]
}