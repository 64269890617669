import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from '../Icon/Icon'
import './Tooltip.scss'


export function Tooltip(props) {


  return (
    <div className={`
      tooltip-container
      pos-${props.position}
      ${props.className ? props.className : ''}
    `}>

      <Icon
        icon={props.icon}
        color="grey"
        variant="flat"
        shape="rounded"
      />

      <div className='tooltip'>
        {props.children}
      </div>

    </div>
  )
}


Tooltip.propTypes = {
  icon:     PropTypes.string,
  position: PropTypes.oneOf(['top-left', 'top-right', 'bottom-left', 'bottom-right']),
  children: PropTypes.any,
}

Tooltip.defaultProps = {
  icon:     'info',
  position: 'top-right',
}