import { defaults } from 'react-chartjs-2'
import { getColor } from '../base'

defaults.global.defaultFontFamily = "'Source Sans Pro', sans-serif"
defaults.global.defaultFontSize = 10
defaults.global.defaultFontColor = getColor('text')

defaults.global.maintainAspectRatio = false

defaults.global.legend.display = false

defaults.global.elements.rectangle.backgroundColor = getColor('text')

defaults.global.elements.arc.backgroundColor = getColor('text')
defaults.global.elements.arc.borderWidth = 1

defaults.global.elements.line.backgroundColor = getColor('theme-primary').slice(0, -1).concat(', 0.2)')
defaults.global.elements.line.borderColor = getColor('theme-primary')
defaults.global.elements.line.borderWidth = 2
defaults.global.elements.line.fill = true

defaults.global.elements.point.radius = 3
defaults.global.elements.point.hoverRadius = 5
defaults.global.elements.point.backgroundColor = getColor('theme-primary')
defaults.global.elements.point.borderWidth = 1
defaults.global.elements.point.hoverBorderWidth = 1
defaults.global.elements.point.borderColor = getColor('theme-primary')

defaults.global.tooltips.displayColors = false
defaults.global.tooltips.titleFontSize = 14
defaults.global.tooltips.bodyFontSize = 14
defaults.global.tooltips.xPadding = 8
defaults.global.tooltips.yPadding = 8

defaults.scale.ticks.beginAtZero = true
defaults.scale.ticks.autoSkip = false
defaults.scale.ticks.precision = 0

defaults.scale.gridLines.color = getColor('grey-lighter')
defaults.scale.gridLines.zeroLineColor = getColor('grey-lighter')
defaults.scale.gridLines.drawBorder = false