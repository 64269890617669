import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../base'


export function TablePagination(props) {

  const { t } = useTranslation()


  return (
    <div className="pagination">

      <nav>
        <Button
          icon="arrowLeft" size="s" variant="secondary" color="default"
          onClick={() => props.gotoPage(0)}
          disabled={!props.canPreviousPage}
        />
        <Button
          icon="dirLeft" size="s" variant="secondary" color="default"
          onClick={() => props.previousPage()}
          disabled={!props.canPreviousPage}
        />
      </nav>

      <div>
        <span>{t('table.pagination', { currentPage: props.pageIndex + 1, totalPages: props.pageOptions.length })}</span>
      </div>

      <nav>
        <Button
          icon="dirRight" size="s" variant="secondary" color="default"
          onClick={() => props.nextPage()}
          disabled={!props.canNextPage}
        />
        <Button
          icon="arrowRight" size="s" variant="secondary" color="default"
          onClick={() => props.gotoPage(props.pageCount - 1)}
          disabled={!props.canNextPage}
        />
      </nav>
      
    </div>
  )
}