import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar } from 'react-chartjs-2'
import { Spinner, Tooltip, Button } from '../base'
import { CSVLink } from 'react-csv'

import { getData } from '../util/api'
import { loginsPerWeekDayQuery } from '../util/loginQueries'
import { matchDepartment, matchItem, matchUser, matchType, matchDate } from '../util/filters'
import { formatDate } from '../util/datetime'


const weekdaysEN = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
const weekdaysNL = ['Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za', 'Zo']


export function LoginsPerWeekDay(props) {

  const { t } = useTranslation()
  const [data, setData] = useState({})
  const [csvData, setCsvData] = useState([])
  const [loading, setLoading] = useState(true)
  const [noResults, setNoResults] = useState(false)
  const weekdays = props.language === 'nl' ? weekdaysNL : weekdaysEN

  const chartOptions = {
    scales: {
      xAxes: [{
        type: 'category',
        labels: weekdays,
        gridLines: {
          display: false
        }
      }]
    },
    tooltips: {
      callbacks: {
        title: (tooltipItem, data) => {
          let day = tooltipItem[0].label
          if(day === 'Mon') return 'Monday'
          if(day === 'Tue') return 'Tuesday'
          if(day === 'Wed') return 'Wednesday'
          if(day === 'Thu') return 'Thursday'
          if(day === 'Fri') return 'Friday'
          if(day === 'Sat') return 'Saturday'
          if(day === 'Sun') return 'Sunday'
          if(day === 'Ma') return 'Maandag'
          if(day === 'Di') return 'Dinsdag'
          if(day === 'Wo') return 'Woensdag'
          if(day === 'Do') return 'Donderdag'
          if(day === 'Vr') return 'Vrijdag'
          if(day === 'Za') return 'Zaterdag'
          if(day === 'Zo') return 'Zondag'
        },
        label: (tooltipItem, data) => {
          let value = Number(tooltipItem.value)
          return t(`chartjs.unique_logins${value === 1 ? '' : '_plural'}`, { count: value })
        }
      }
    }
  }
  

  useEffect(() => {
    const abortController = new AbortController()

    let query = loginsPerWeekDayQuery()
    query = matchDepartment(query, props.departmentFilter)
    query = matchUser(query, props.userFilter, props.includeUser)
    query = matchItem(query, props.itemFilter, props.includeItem)
    query = matchType(query, props.typeFilter, props.includeType)
    query = matchDate(query, props.dateFilter)

    async function fetchData(query) {
      setLoading(true)
      const data = await getData(query, abortController)
      if(!data) return

      let csvData = data
      csvData.forEach(element => {
        if(element.weekday === 1) element.weekday = weekdays[0]
        if(element.weekday === 2) element.weekday = weekdays[1]
        if(element.weekday === 3) element.weekday = weekdays[2]
        if(element.weekday === 4) element.weekday = weekdays[3]
        if(element.weekday === 5) element.weekday = weekdays[4]
        if(element.weekday === 6) element.weekday = weekdays[5]
        if(element.weekday === 7) element.weekday = weekdays[6]
      })
      setCsvData(csvData)
  
      const chartData = [0, 0, 0, 0, 0, 0, 0]
      data.forEach(item => {
        if(item.weekday === 'Mon' || item.weekday === 'Ma') chartData[0] = item.value
        if(item.weekday === 'Tue' || item.weekday === 'Di') chartData[1] = item.value
        if(item.weekday === 'Wed' || item.weekday === 'Wo') chartData[2] = item.value
        if(item.weekday === 'Thu' || item.weekday === 'Do') chartData[3] = item.value
        if(item.weekday === 'Fri' || item.weekday === 'Vr') chartData[4] = item.value
        if(item.weekday === 'Sat' || item.weekday === 'Za') chartData[5] = item.value
        if(item.weekday === 'Sun' || item.weekday === 'Zo') chartData[6] = item.value
      })
  
      if(data.length === 0) setNoResults(true)
      else setNoResults(false)
  
      setData({
        datasets: [{
          data: chartData
        }]
      })
      setLoading(false)
    }
    fetchData(query)

    return () => abortController.abort()

  }, [props, weekdays])


  
  const csvHeaders = [
    { key: 'weekday', label: t('table.day') },
    { key: 'value', label: t('table.total_logins') }
  ]



  return (
    <>
      <Tooltip>{t('tooltips.logins_per_weekday')}</Tooltip>
      <h4>{t('titles.logins_per_weekday')}</h4>
      <div className="chart">
        {noResults ? <span>{t('no_results')}</span> :
          <Bar data={data} options={chartOptions} />
        }
      </div>
      <Button label="CSV Export" icon="download" className="csv" size="s" variant="ghost">
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename={`lrs-logins-per-weekday-${formatDate(props.dateFilter.startDate)}-${formatDate(props.dateFilter.endDate)}.csv`}
          className="csv-link"
          separator={';'}
        />
      </Button>
      {loading && <Spinner mask />}
    </>
  )
}