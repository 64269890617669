import React, { useState, useEffect } from 'react'
import { useTable, useSortBy, usePagination } from 'react-table'
import { Table, Icon, Spinner } from '../base'

import { getSimpleData } from '../util/api'
import { queries } from '../util/queries'
import { TablePagination } from './TablePagination'
import '../tables/Table.scss'


export function QuestionsTable({ columns, data }) {

  const [selectedQuestion, setSelectedQuestion] = useState('')
  const [answerData, setAnswerData] = useState()
  const [loading, setLoading] = useState(false)
  

  useEffect(() => {
    const abortController = new AbortController()

    async function fetchData() {
      if(!selectedQuestion) return

      setLoading(true)

      let query = [...[{
        '$match': {
          'statement.object.definition.extensions.http://skillstown/question/id': {
            '$exists': true,
            '$in': [selectedQuestion._id]
          }
        }
      }],
      ...queries.questionAnswers]
  
      let data = await getSimpleData(query, abortController)
      if(!data) return
      
      data = data[0]

      if(data.answerType === 'choice') {
        data.correctAnswers = data.correctAnswers[0].split('[,]')
      } else {
        data.correctAnswers = data.correctAnswers[0].split('\r\n')
      }
  
      setAnswerData(data)
      setLoading(false)
    }
    fetchData()

    return () => abortController.abort()

  }, [selectedQuestion])



  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{
          id: 'score',
          desc: false
        }],
        hiddenColumns: ['_id'],
        pageIndex: 0,
        pageSize: 5
      },
    },
    useSortBy,
    usePagination
  )



  return (
    <div className="exam-questions">

      <div className="selected-question">
        {answerData ? <>
          <p>{answerData.label}</p>
          <ul>{answerData.answerType === 'choice' ?
            answerData.answers.map(answer => {
              return (
                <li
                  key={answer.id}
                  className={answerData.correctAnswers.includes(answer.id) ? 'correct' : 'wrong'}
                >
                  {answerData.correctAnswers.includes(answer.id) ?
                    <Icon variant="flat" size="xs" icon="check" color="green" />
                  :
                    <Icon variant="flat" size="xs" icon="close" color="red" />
                  }
                  <span>{answer.description['en-US']}</span>
                </li>
              )
            })
          :
            answerData.correctAnswers.map((answer, index) => {
              return (
                <li key={index} className="correct">
                  <Icon variant="flat" size="xs" icon="check" color="green" />
                  <span>{answer}</span>
                </li>
              )
            })
          }</ul>
          </>
        : ''}
        {loading && <Spinner mask />}
      </div>
      


      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th className={column.id} {...column.getHeaderProps(column.getSortByToggleProps())} title="">
                  {column.render('Header')}
                    {column.isSorted
                    ? column.isSortedDesc
                      ? <Icon icon="dirDown" size="xs" color="default" />
                      : <Icon icon="dirUp" size="xs" color="default" />
                    : <Icon size="xs" color="default" />}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  switch(cell.column.id) {
                    case 'score': return (
                      <td className={cell.column.id} {...cell.getCellProps()}>
                        {Number(cell.value)}%
                      </td>
                    )
                    case 'label': return (
                      <td className={`${cell.column.id} ${cell.row.values._id === selectedQuestion._id ? 'selected' : ''}`} {...cell.getCellProps()}>
                        <button onClick={event => setSelectedQuestion(cell.row.values)}>
                          {cell.value || '(question)'}
                        </button>
                      </td>
                    )
                    default: return (
                      <td className={cell.column.id} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  }
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>

      <TablePagination
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        pageIndex={pageIndex}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        pageCount={pageCount}
        pageOptions={pageOptions}
      />

    </div>
  )
}