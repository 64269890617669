import React from 'react'
import { useTable, useSortBy, usePagination } from 'react-table'
import { Table, Icon } from '../base'
import './Table.scss'

import { TablePagination } from './TablePagination'
import { formatDateTime } from '../util/datetime.js'


export function UserActivityTable({ columns, data, ...props }) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{
          id: 'max_timestamp',
          desc: true
        }],
        pageIndex: 0,
        pageSize: 10
      },
    },
    useSortBy,
    usePagination
  )


  return (
    <>
      <Table size="xs" {...getTableProps({className: props.className})}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th className={column.id} {...column.getHeaderProps(column.getSortByToggleProps())} title="">
                  {column.render('Header')}
                    {column.isSorted
                    ? column.isSortedDesc
                      ? <Icon icon="dirDown" size="xs" color="default" />
                      : <Icon icon="dirUp" size="xs" color="default" />
                    : <Icon size="xs" color="default" />}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  switch(cell.column.id) {
                    case 'progress': return (
                      <td className={cell.column.id} {...cell.getCellProps()}>
                        {cell.value > 0 && <progress max='100' value={cell.value || 0} title={`${cell.value || 0}%`} />}
                      </td>
                    )
                    case 'min_timestamp':
                    case 'max_timestamp': return (
                      <td className={cell.column.id} {...cell.getCellProps()}>
                        {formatDateTime(cell.value)}
                      </td>
                    )
                    default: return (
                      <td className={cell.column.id} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  }
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>

      <TablePagination
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        pageIndex={pageIndex}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        pageCount={pageCount}
        pageOptions={pageOptions}
      />
    </>
  )
}