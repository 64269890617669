export const mapDepartments = (event) => {
  let departments = event.data.departments;

  departments = departments.map(department => ({
    ...department,
    value: department.id,
    label: department.name,
  }));

  return getHierarchicalDepartments(departments);
}

const getHierarchicalDepartments = (departments) => {
  const findChildren = (departmentId, parentNames = []) => {
    const children = [];

    for(const department of departments) {
      if(department.parent === departmentId) {
        children.push({
          item: department,
          label: [...parentNames, department.name].join(' > '),
          children: findChildren(department.id, [...parentNames, department.name])
        });
      }
    }

    return children;
  }

  const hierarchicalDepartments = getHierarchicalRoots(departments).map(root => ({
      item: root,
      label: root.name,
      children: findChildren(root.id, [root.name])
    })
  );

  return hierarchicalDepartments;
}

const getHierarchicalRoots = (departments) => {
  const roots = [];

  main: for(const department of departments) {
    for(const potentialParent of departments) {
      if(department.parent === potentialParent.id) {
        continue main;
      }
    }

    roots.push(department);
  }

  return roots;
}

export const getDepartmentChildrenIds = (departments, departmentId) => {
  const findDepartmentWithId = (department, departmentId) => {
    if(department.item.id === departmentId) {
      return department;
    }

    for(const child of department.children) {
      const response = findDepartmentWithId(child, departmentId)
      if(response) {
        return response;
      }
    }

    return null;
  }

  const getAllChildIds = (department) => {
    let ids = [department.item.id];

    for(const current of department.children) {
      ids = [...ids, ...getAllChildIds(current)];
    }

    return ids;
  }

  if(!(departments.length === 1 && departments[0].item.id === departmentId)) {
    for(const department of departments) {
      const response = findDepartmentWithId(department, departmentId)
      if(response) {
        return getAllChildIds(response);
      }
    }
  }

  return [];
}

export const listDepartments = (departments) => {
  let list = [];

  const searchChildren = (department, list = []) => {
    for(const child of department) {
      list = [...list, {
        id: child.item.id,
        value: child.item.id,
        label: child.label
      }, ...searchChildren(child.children)];
    }

    return list;
  }

  for(const root of departments) {
    list = [...list, {
      id: root.item.id,
      value: root.item.id,
      label: root.label
    }, ...searchChildren(root.children)];
  }

  return list;
}