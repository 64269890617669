import React, { useEffect, useState, useRef } from 'react'
import { createPortal } from 'react-dom'
import FocusTrap from 'focus-trap-react'
import PropTypes from 'prop-types'

import { Button } from '../Button/Button'
import './Modal.scss'


export function Modal(props) {

  const modalContent = useRef()
  const [ shouldRender, setShouldRender] = useState(props.show)
  
  
  useEffect(() => {

    if(props.show) {
      setShouldRender(true)
      document.body.classList.add('disable-scrolling')
    }

    function handleKeyDown(event) {
      if(event.key === 'Escape') props.onHide()
      props.onKeyDown && props.onKeyDown(event)
    }

    window.addEventListener('keydown', handleKeyDown)
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      document.body.classList.remove('disable-scrolling')
    }

  }, [props])


  function onAnimationEnd() {
    if(!props.show) setShouldRender(false)
  }

  function hideModal() {
    props.onHide()
  }
  
  function handleClick(event) {
    if(!modalContent.current.contains(event.target)) {
      hideModal()
    }
  }


  const markup = (
    <div
      style={{ animation: `${props.show ? 'enterModal 400ms' : 'exitModal 250ms'}`}}
      onAnimationEnd={onAnimationEnd}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
      <div
        className={`modal-container ${props.className ? props.className : ''}`}
        onClick={handleClick}
        id={props.id}
        role={props.role}
        tabIndex="-1"
        aria-modal="true"
        aria-labelledby="modal-label"
      >
        <FocusTrap focusTrapOptions={{ allowOutsideClick: true }}>
          <div
            className="modal-content"
            style={{ animation: `${props.show ? 'enterModalContent 400ms' : 'exitModalContent 250ms'}`}}
            ref={modalContent}
          >
            <header>
              <strong id="modal-label">{props.label}</strong>
              <Button
                onClick={hideModal}
                icon="close"
                shape="circle"
                variant="secondary"
                color="default"
                aria-label="Close Modal"
              />
            </header>
            <main>{props.children}</main>
          </div>
        </FocusTrap>

      </div>
    </div>
  )


  if(!shouldRender) return null
  else return createPortal(markup, document.body)
}


Modal.propTypes = {
  show:       PropTypes.bool.isRequired,
  onHide:     PropTypes.func.isRequired,
  onKeyDown:  PropTypes.func,
  role:       PropTypes.oneOf(['dialog', 'alertdialog']),
  label:      PropTypes.string,
  id:         PropTypes.string,
  children:   PropTypes.any,
  className:  PropTypes.string,
}

Modal.defaultProps = {
  show:       false,
  role:       'dialog',
}