import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import { Icon } from '../Icon/Icon'
import './Button.scss'


export const Button = forwardRef((props, ref) => {
  
  let ariaLabel
  if(!props.label && props.icon) ariaLabel = props.ariaLabel || props.icon


  function handleClick(event) {
    props.onClick && props.onClick(event)
  }

  function handleKeyDown(event) {
    props.onKeyDown && props.onKeyDown(event)
  }

  function handleKeyUp(event) {
    props.onKeyUp && props.onKeyUp(event)
  }


  return (
    <button
      className={`
        variant-${props.variant}
        shape-${props.shape}
        size-${props.size}
        color-${props.color}
        icon-position-${props.iconPos}
        ${!props.label ? 'icon-only' : ''}
        ${props.className ? props.className : ''}
      `}
      onClick={handleClick}
      ref={ref}
      id={props.id}
      name={props.name}
      type={props.type}
      onKeyDown={handleKeyDown}
      onKeyUp={handleKeyUp}
      tabIndex={props.tabIndex}
      aria-label={ariaLabel}
      disabled={props.disabled}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={props.autoFocus}
    >
      {props.children}
      {props.icon &&
        <Icon
          icon={props.icon}
          variant={props.iconVariant}
          className={props.iconAni}
          size={props.size}
          color={props.color}
        />
      }
      {props.label &&
        <span>{props.label}</span>
      }
    </button>
  )
})


Button.displayName = 'Button'

Button.propTypes = {
  onClick:    PropTypes.func,
  type:       PropTypes.oneOf(['button', 'submit', 'reset']),
  label:      PropTypes.string,
  name:       PropTypes.string,
  id:         PropTypes.string,
  shape:      PropTypes.oneOf(['circle', 'rounded', 'square']),
  color:      PropTypes.oneOf(['primary', 'default', 'red', 'orange', 'yellow', 'green', 'blue', 'purple', 'pink', 'grey']),
  variant:    PropTypes.oneOf(['flat', 'ghost', 'secondary']),
  icon:       PropTypes.string,
  iconVariant:PropTypes.string,
  iconPos:    PropTypes.oneOf(['left', 'right']),
  iconAni:    PropTypes.oneOf(['spin', 'pulse', 'rotate', 'up-down', 'left-right', 'blink', 'shake']),
  size:       PropTypes.oneOf(['s', 'm', 'l', 'xl', 'responsive']),
  tabIndex:   PropTypes.string,
  ariaLabel:  PropTypes.string,
  disabled:   PropTypes.bool,
  autoFocus:  PropTypes.bool,
  children:   PropTypes.any,
  className:  PropTypes.string,
}

Button.defaultProps = {
  variant:  'flat',
  shape:    'rounded',
  color:    'primary',
  size:     'm',
  iconPos:  'left',
}