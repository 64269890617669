import React from 'react'
import PropTypes from 'prop-types'

import './Table.scss'


export function Table(props) {

  return (
    <div
      className={`
        table-container
        ${props.scrollSnap ? 'scroll-snap' : ''}
        ${props.className ? props.className : ''}
      `}
    >
      <table
        className={`
          size-${props.size}
          white-space-${props.whiteSpace}
          ${props.fixedHeader ? 'fixed-header' : ''}
        `}
      >
        {props.children}
      </table>
    </div>
  )
}


Table.propTypes = {
  children:     PropTypes.any,
  size:         PropTypes.oneOf(['xs', 's', 'm', 'l']),
  whiteSpace:   PropTypes.oneOf(['normal', 'nowrap']),
  fixedHeader:  PropTypes.bool,
  scrollSnap:   PropTypes.bool,
}

Table.defaultProps = {
  size:         's',
  fixedHeader:  false,
  scrollSnap:   false,
  whiteSpace:   'nowrap',
}