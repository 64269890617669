import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from '../Icon/Icon'
import './Spinner.scss'


export function Spinner(props) {
  return (
    <div
      className={`
        loading-spinner
        ${props.mask ? 'mask' : ''}
      `}
    >
      <Icon
        icon="spinner"
        className="spin"
        size={props.size}
        ariaHidden={true}
      />
    </div>
  )
}


Spinner.propTypes = {
  size:       PropTypes.oneOf(['s', 'm', 'l', 'xl', 'xxl']),
  inline:     PropTypes.bool,
  mask:       PropTypes.bool,
}

Spinner.defaultProps = {
  size:       'xxl',
  inline:     false,
  mask:       false,
}