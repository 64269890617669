import React, { useState } from 'react'
import { useTable, useSortBy, usePagination } from 'react-table'
import { Table, Icon } from '../base'

import { TablePagination } from './TablePagination'
import { ExamModal } from '../modals/ExamModal'
import './Table.scss'


export function ExamsTable({ columns, data, departmentFilter, userFilter, dateFilter, includeUser }) {

  const [selectedExam, setSelectedExam] = useState('')
  const [showModal, setShowModal] = useState(false)

  function openModal() {
    setShowModal(true)
  }
  function hideModal() {
    setShowModal(false)
  }


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: [{
          id: 'attempts',
          desc: true
        }],
        hiddenColumns: ['_id'],
        pageIndex: 0,
        pageSize: 10
      },
    },
    useSortBy,
    usePagination
  )



  return (
    <>

      <Table {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th className={column.id} {...column.getHeaderProps(column.getSortByToggleProps())} title="">
                  {column.render('Header')}
                    {column.isSorted
                    ? column.isSortedDesc
                      ? <Icon icon="dirDown" size="xs" color="default" />
                      : <Icon icon="dirUp" size="xs" color="default" />
                    : <Icon size="xs" color="default" />}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map(row => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  switch(cell.column.id) {
                    case 'label': return (
                      <td className={cell.column.id} {...cell.getCellProps()}>
                        <button onClick={event => {
                          setSelectedExam(cell.row.values)
                          openModal()
                        }}>
                          {cell.render('Cell')}
                        </button>
                      </td>
                    )
                    default: return (
                      <td className={cell.column.id} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </td>
                    )
                  }
                })}
              </tr>
            )
          })}
        </tbody>
      </Table>

      <TablePagination
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        pageIndex={pageIndex}
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        pageCount={pageCount}
        pageOptions={pageOptions}
      />



      <ExamModal
        showModal={showModal}
        hideModal={hideModal}
        selectedExam={selectedExam}
        departmentFilter={departmentFilter}
        userFilter={userFilter}
        dateFilter={dateFilter}
        includeUser={includeUser}
      />

    </>
  )
}