import React, { useState, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CSVLink } from 'react-csv'
import { Spinner, Modal, Button } from '../base'

import { QuestionsTable } from '../tables/QuestionsTable'
import { getData } from '../util/api'
import { queries } from '../util/queries'
import { matchDepartment, matchUser, matchDate } from '../util/filters'
import { formatDate } from '../util/datetime'
import './Modal.scss'


export function ExamModal(props) {

  const { t } = useTranslation()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  

  useEffect(() => {
    const abortController = new AbortController()

    let query = [...[{
      '$match': {
        'statement.object.definition.extensions.http://skillstown/course/id': {
          '$in': [props.selectedExam._id]
        }
      }
    }],
    ...queries.questionStatistics]

    query = matchDepartment(query, props.departmentFilter)
    query = matchUser(query, props.userFilter, props.includeUser)
    query = matchDate(query, props.dateFilter)

    async function fetchData(query) {
      setLoading(true)
      
      let data = await getData(query, abortController)
      if(!data) return
      
      data.forEach(element => {
        element.score = Number(element.score).toFixed(0)
      })
  
      setData(data)
      setLoading(false)
    }
    fetchData(query)

    return () => abortController.abort()

  }, [props])



  const memoData = useMemo(() => data, [data])


  const tableColumns = useMemo(() => [
    { accessor: '_id', Header: 'ID' },
    { accessor: 'label', Header: t('table.question') },
    { accessor: 'total', Header: t('table.answered') },
    { accessor: 'correct', Header: t('table.correct') },
    { accessor: 'wrong', Header: t('table.wrong') },
    { accessor: 'score', Header: t('table.percentage') },
  ], [t])

  const csvHeaders = [
    { key: '_id', label: 'ID' },
    { key: 'label', label: t('table.question') },
    { key: 'total', label: t('table.answered') },
    { key: 'correct', label: t('table.correct') },
    { key: 'wrong', label: t('table.wrong') },
    { key: 'score', label: t('table.percentage') },
  ]



  return (
    <Modal
      show={props.showModal}
      onHide={props.hideModal}
      label={t('exam') + ': ' + props.selectedExam.label}
    >
      <QuestionsTable
        data={memoData}
        columns={tableColumns}
      />
      <Button label="CSV Export" icon="download" className="csv" size="s" variant="ghost">
        <CSVLink
          data={memoData}
          headers={csvHeaders}
          filename={`lrs-${props.selectedExam?.label?.replace(/\s+/g, '-')}-${formatDate(props.dateFilter.startDate)}-${formatDate(props.dateFilter.endDate)}.csv`}
          className="csv-link"
          separator={';'}
        />
      </Button>
      {loading && <Spinner mask />}
    </Modal>
  )
}