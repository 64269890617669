import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import { addDays, endOfDay, startOfDay, startOfMonth, endOfMonth, addMonths, startOfWeek, endOfWeek } from 'date-fns'
import { DateRangePicker, createStaticRanges } from 'react-date-range'
import { nl, enGB } from 'date-fns/locale'


export function DatePicker(props) {

  const { t } = useTranslation()
  const [ shouldRender, setShouldRender] = useState(props.show)
  const staticRanges = createStaticRanges([
    {
      label: t('datepicker.today'),
      range: () => ({
        startDate: startOfDay(new Date()),
        endDate: endOfDay(new Date())
      })
    },
    {
      label: t('datepicker.yesterday'),
      range: () => ({
        startDate: startOfDay(addDays(new Date(), -1)),
        endDate: endOfDay(addDays(new Date(), -1))
      })
    },
    {
      label: t('datepicker.last_n_days', { count: 7 }),
      range: () => ({
        startDate: startOfDay(addDays(new Date(), -7)),
        endDate: endOfDay(new Date())
      })
    },
    {
      label: t('datepicker.last_n_days', { count: 14 }),
      range: () => ({
        startDate: startOfDay(addDays(new Date(), -14)),
        endDate: endOfDay(new Date())
      })
    },
    {
      label: t('datepicker.last_n_days', { count: 30 }),
      range: () => ({
        startDate: startOfDay(addDays(new Date(), -30)),
        endDate: endOfDay(new Date())
      })
    },
    {
      label: t('datepicker.last_week'),
      range: () => ({
        startDate: startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
        endDate: endOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 })
      })
    },
    {
      label: t('datepicker.last_month'),
      range: () => ({
        startDate: startOfMonth(addMonths(new Date(), -1)),
        endDate: endOfMonth(addMonths(new Date(), -1))
      })
    },
    {
      label: t('datepicker.alltime'),
      range: () => ({
        startDate: new Date('2020/1/1'),
        endDate: endOfDay(new Date())
      })
    }
  ])


  useEffect(() => {
    if(props.show) {
      setShouldRender(true)
    }
  }, [props.show])

  function onAnimationEnd() {
    if(!props.show) setShouldRender(false)
  }


  if(!shouldRender) return null

  else return (
    <div
      className="datepicker-container"
      style={{ animation: `${props.show ? 'enterDatePicker' : 'exitDatePicker'} 250ms`}}
      onAnimationEnd={onAnimationEnd}
    >
      <DateRangePicker
        ranges={props.ranges}
        onChange={props.onChange}
        minDate={props.firstTimestamp}
        maxDate={endOfDay(new Date())}
        scroll={{ enabled: true }}
        staticRanges={staticRanges}
        inputRanges={[]}
        showDateDisplay={false}
        weekStartsOn={1}
        locale={props.language === 'nl' ? nl : enGB}
      />
    </div>
  )
}