export const icons = {
  arrowLeft: <g><path d="M3.69,14,13.81,4.65a2.69,2.69,0,1,1,3.66,3.94l-4.89,4.53H26.14a2.69,2.69,0,0,1,0,5.38h-14l5.3,4.91a2.69,2.69,0,1,1-3.66,3.94L3.69,18a2.69,2.69,0,0,1,0-3.94Z"/></g>,
  arrowRight: <g><path d="M28,18,17.85,27.35a2.69,2.69,0,1,1-3.65-3.94l4.88-4.53H5.52a2.69,2.69,0,0,1,0-5.38h14L14.2,8.59a2.69,2.69,0,1,1,3.65-3.94L28,14A2.69,2.69,0,0,1,28,18Z"/></g>,
  certificate: <g><path d="M23.579,21.294a12,12,0,1,0-15.158,0L4.974,27.265a.667.667,0,0,0,.711.987l4.3-.877,1.387,4.169a.666.666,0,0,0,.565.453c.022,0,.045,0,.067,0a.668.668,0,0,0,.578-.333l4.454-7.719a11.919,11.919,0,0,0,5.459-1.87l2.64,4.571-3.425-.7a.67.67,0,0,0-.766.443l-1.1,3.322-1.671-2.9a.667.667,0,1,0-1.155.667l2.413,4.181A.666.666,0,0,0,20,32c.022,0,.045,0,.067,0a.669.669,0,0,0,.566-.453l1.386-4.169,4.3.877a.667.667,0,0,0,.711-.987ZM5.333,12A10.667,10.667,0,1,1,16,22.667,10.679,10.679,0,0,1,5.333,12Zm6.831,17.715-1.1-3.322a.667.667,0,0,0-.766-.443l-3.425.7,2.64-4.571a11.917,11.917,0,0,0,5.969,1.9Z"/><path d="M12.736,13.141a.268.268,0,0,1,.077.237l-.631,3.68a.268.268,0,0,0,.389.282l3.3-1.737a.269.269,0,0,1,.25,0l3.3,1.737a.268.268,0,0,0,.389-.282l-.631-3.68a.268.268,0,0,1,.077-.237l2.673-2.606a.268.268,0,0,0-.149-.457l-3.694-.536a.27.27,0,0,1-.2-.147L16.24,6.047a.268.268,0,0,0-.48,0L14.108,9.4a.27.27,0,0,1-.2.147l-3.694.536a.268.268,0,0,0-.149.457Z"/></g>,
  check: <g><path d="M12.8,27.9L12.8,27.9c-1,0-2-0.4-2.7-1.2l-7.3-8.5c-1.3-1.5-1.1-3.7,0.4-4.9c1.5-1.2,3.7-1.1,4.9,0.4l4.6,5.4L23.9,5.7c1.3-1.5,3.5-1.6,4.9-0.4c1.5,1.3,1.6,3.5,0.4,4.9L15.4,26.7C14.8,27.5,13.8,27.9,12.8,27.9z"/></g>,
  close: <g><path d="M26.61,22l-6-5.95,6-5.95A3.3,3.3,0,1,0,22,5.39l-5.95,6-5.95-6a3.3,3.3,0,0,0-4.66,4.66l6,5.95L5.39,22a3.3,3.3,0,0,0,4.66,4.66l5.95-6,5.95,6A3.3,3.3,0,1,0,26.61,22Z"/></g>,
  date: <g><path d="M26.725,2.539H23.149V1.258a1.258,1.258,0,1,0-2.516,0V2.539h-9.37V1.258a1.258,1.258,0,1,0-2.515,0V2.539H5.277A4.011,4.011,0,0,0,1.269,6.545V27.994A4.011,4.011,0,0,0,5.277,32H26.725a4.01,4.01,0,0,0,4.006-4.006V6.545A4.01,4.01,0,0,0,26.725,2.539Zm1.49,9.079V27.994a1.492,1.492,0,0,1-1.49,1.491H5.277a1.492,1.492,0,0,1-1.49-1.491V11.618ZM10.006,7.31a1.26,1.26,0,0,0,1.257-1.257v-1h9.37v1a1.258,1.258,0,0,0,2.516,0v-1h3.576a1.492,1.492,0,0,1,1.49,1.491V9.1H3.787V6.545a1.492,1.492,0,0,1,1.49-1.491H8.748v1A1.26,1.26,0,0,0,10.006,7.31Z"/></g>,
  dirDown: <g><path d="M16,22a2.06,2.06,0,0,1-1.46-.6l-8.2-8.12a2.08,2.08,0,0,1,2.92-3L16,17l6.74-6.68a2.08,2.08,0,0,1,2.92,3l-8.2,8.12A2.06,2.06,0,0,1,16,22Z"/></g>,
  dirLeft: <g><path d="M9.86,15.9a2,2,0,0,1,.61-1.46l8.12-8.2a2.08,2.08,0,0,1,2.95,2.92L14.86,15.9l6.68,6.73a2.08,2.08,0,0,1,0,2.94,2.06,2.06,0,0,1-2.93,0l-8.12-8.19A2,2,0,0,1,9.86,15.9Z"/></g>,
  dirRight: <g><path d="M22.14,15.9a2,2,0,0,1-.61,1.46l-8.12,8.19a2.06,2.06,0,0,1-2.93,0,2.08,2.08,0,0,1,0-2.94l6.68-6.73L10.46,9.16a2.08,2.08,0,0,1,2.95-2.92l8.12,8.2A2,2,0,0,1,22.14,15.9Z"/></g>,
  dirUp: <g><path d="M16,9.76a2.06,2.06,0,0,1,1.46.6l8.2,8.12a2.08,2.08,0,0,1-2.92,2.95L16,14.76,9.26,21.43a2.08,2.08,0,0,1-2.92-2.95l8.2-8.12A2.06,2.06,0,0,1,16,9.76Z"/></g>,
  download: <g><path d="M8,17.39l6.84,7.76a2,2,0,0,0,1.36.61,1.79,1.79,0,0,0,1.34-.65l6.6-7.93a1.79,1.79,0,0,0-.23-2.51,1.81,1.81,0,0,0-1.14-.41,1.78,1.78,0,0,0-1.37.64l-3.57,4.3V1.78a1.78,1.78,0,0,0-3.56,0V19.13L10.61,15A1.77,1.77,0,1,0,8,17.39Z"/><path d="M22.51,32h-13a6.76,6.76,0,0,1-6.76-6.76V24.9a1.49,1.49,0,1,1,3,0v.34A3.79,3.79,0,0,0,9.49,29h13a3.79,3.79,0,0,0,3.79-3.79,1.49,1.49,0,0,1,3,0A6.76,6.76,0,0,1,22.51,32Z"/></g>,
  password: <g><path d="M25.59,12.093H24.459V9.042a8.4,8.4,0,1,0-16.8,0v3.051H6.41A2.511,2.511,0,0,0,3.9,14.6V28.849A2.511,2.511,0,0,0,6.41,31.358H25.59A2.511,2.511,0,0,0,28.1,28.849V14.6A2.511,2.511,0,0,0,25.59,12.093ZM9.662,9.042a6.4,6.4,0,1,1,12.8,0v3.051H9.662ZM26.1,28.849a.51.51,0,0,1-.508.509H6.41a.51.51,0,0,1-.508-.509V14.6a.509.509,0,0,1,.508-.509H25.59a.509.509,0,0,1,.508.509Z"/><path d="M16,17.347a2.992,2.992,0,0,0-1,5.815v2.185a1,1,0,0,0,2,0V23.163a2.993,2.993,0,0,0-1-5.816Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,16,21.347Z"/></g>,
  info: <g><path d="M18.88,13.49A2.88,2.88,0,0,0,16,10.61a2.89,2.89,0,0,0-2.88,2.88V29.12a2.88,2.88,0,1,0,5.76,0Z"/><path d="M18.88,2.88A2.88,2.88,0,0,0,16,0a2.89,2.89,0,0,0-2.88,2.88h0a2.88,2.88,0,1,0,5.76,0Z"/></g>,
  spinner: <g><path d="M.969,13.705a3.351,3.351,0,0,1,4.676,0,3.2,3.2,0,0,1,0,4.589,3.271,3.271,0,0,1-1,.665,11.486,11.486,0,0,0,3.046,5.2,11.913,11.913,0,0,0,16.584.037c.018-.019.064-.065.083-.082a1,1,0,0,1,1.394.041.986.986,0,0,1-.024,1.394,13.939,13.939,0,0,1-19.454,0,13.427,13.427,0,0,1-3.637-6.371,3.3,3.3,0,0,1-1.667-.88A3.2,3.2,0,0,1,.969,13.705Z"/><path d="M26.355,13.705a3.292,3.292,0,0,1,1-.663,11.492,11.492,0,0,0-3.045-5.2A11.914,11.914,0,0,0,7.724,7.808c-.018.019-.063.065-.083.082a1,1,0,0,1-1.394-.041.986.986,0,0,1,.024-1.394,13.94,13.94,0,0,1,19.455,0,13.445,13.445,0,0,1,3.637,6.37,3.318,3.318,0,0,1,1.669.88,3.2,3.2,0,0,1,0,4.589,3.351,3.351,0,0,1-4.677,0A3.2,3.2,0,0,1,26.355,13.705Z"/></g>,
  star: <g><path d="M8.56,19.42a.64.64,0,0,1,.18.54L7.3,28.35a.6.6,0,0,0,.88.64l7.54-4a.61.61,0,0,1,.56,0l7.54,4a.6.6,0,0,0,.88-.64L23.26,20a.64.64,0,0,1,.18-.54l6.09-5.94a.61.61,0,0,0-.33-1l-8.43-1.22a.62.62,0,0,1-.46-.34L16.55,3.25a.62.62,0,0,0-1.1,0l-3.76,7.63a.62.62,0,0,1-.46.34L2.8,12.44a.61.61,0,0,0-.33,1Z"/></g>,
  user: <g><path d="M16,13.66A6.64,6.64,0,1,0,9.36,7,6.65,6.65,0,0,0,16,13.66ZM12.21,7A3.79,3.79,0,1,1,16,10.82,3.79,3.79,0,0,1,12.21,7Z"/><path d="M16,15A11.67,11.67,0,0,0,4.34,26.63a1.42,1.42,0,1,0,2.84,0,8.82,8.82,0,1,1,17.64,0v3.56a1.42,1.42,0,0,0,2.84,0V26.63A11.67,11.67,0,0,0,16,15Z"/></g>
}