import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import { getColor } from '../base'

import { getData } from '../util/api'
import { queries } from '../util/queries'
import { matchDepartment, matchUser, matchItem, matchDate } from '../util/filters'


export function TypeFilter(props) {
  
  const { t } = useTranslation()
  const [options, setOptions] = useState([])
  

  async function loadOptions() {
    setOptions([ {label: t('loading'), value: 0, isDisabled: true} ])
    let query = queries.allTypes
    query = matchDepartment(query, props.departmentFilter)
    query = matchUser(query, props.userFilter, props.includeUser)
    query = matchItem(query, props.itemFilter, props.includeItem)
    query = matchDate(query, props.dateFilter)
    const types = await getData(query)
    setOptions(types)
  }


  return (
    <Select
      isMulti
      options={options}
      onMenuOpen={loadOptions}
      value={props.value}
      onChange={props.onChange}
      placeholder={t('select_types')}
      styles={{
        control: (base, state) => ({
          ...base,
          boxShadow: 'none',
          borderColor: state.isFocused ? getColor() : getColor('grey-light'),
          '&:hover': { borderColor: state.isFocused ? getColor() : getColor('grey-dark') }
        })
      }}
    />
  )
}